import { API_ENDPOINTS } from "../constants/endpoints";
import { apiSlice, handleResponse } from "./base.service";

export const utilsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteRow: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.DELETE_ROW,
        method: "DELETE",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    activeToggleRow: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.ACTIVE_TOGGLE_ROW,
        method: "PUT",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    updateSettings: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.UPDATE_GENERAL_SETTINGS,
        method: "PUT",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    getSetting: builder.query({
      query: () => ({
        url: API_ENDPOINTS.GET_GENERAL_SETTINGS,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    getDashboardInfo: builder.query({
      query: () => ({
        url: API_ENDPOINTS.GET_DASHBOARD_INFO,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    getUsers: builder.query({
      query: ({params}) => ({
        url: API_ENDPOINTS.GET_USERS,
        params
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
  }),
});

export const {
  useDeleteRowMutation,
  useActiveToggleRowMutation,
  useUpdateSettingsMutation,
  useGetSettingQuery,
  useGetDashboardInfoQuery,
  useGetUsersQuery
} = utilsApiSlice;
