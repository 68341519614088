import { Box, useTheme } from "@mui/material";
import ProfileMenu from "components/ProfileMenu";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box display="flex" justifyContent="space-between" p={2} sx={{backgroundColor:`${colors.whiteAccent[100]}`}}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.whiteAccent[100]}
        borderRadius="3px"
        // sx={{border:`solid 1px ${colors.whiteAccent[400]}`}}
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
      </Box>

      {/* ICONS */}
      <Box display="flex">
        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton> */}
        {/* <IconButton>
          <SettingsOutlinedIcon />
        </IconButton> */}
        <ProfileMenu />
      </Box>
    </Box>
  );
};

export default Topbar;
