import { Box, Button, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "components/Header";
import parse from "html-react-parser";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCouponQuery } from "services/coupon.service";
import { formatTimestamp } from "utils/common";
import dataGridStyles from "../../styles/dataGridStyles";

const View = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  const { id: couponId } = useParams();

  const { data: couponData } = useGetCouponQuery(couponId, {
    skip: !couponId,
    refetchOnMountOrArgChange: true,
  });

  const navigate = useNavigate();

  return (
    <Box m="20px">
      <Box sx={styles.mainHeadings}>
        <Header title="COUPON DETAILS" />

        <Box display="flex" justifyContent="end" mt="20px">
          <Button
            onClick={() => navigate("/coupons")}
            color="secondary"
            variant="contained"
            sx={styles.buttonMd}
          >
            Back to Coupons
          </Button>
        </Box>
      </Box>
      <Box sx={styles.formContainer}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
        >
          <Typography variant="h6" sx={{ gridColumn: "span 1" }}>
            Code:
          </Typography>
          <Typography sx={{ gridColumn: "span 1" }}>
            {couponData?.code}
          </Typography>
          {couponData?.discountType !== "bundle" && (
            <>
              <Typography variant="h6" sx={{ gridColumn: "span 1" }}>
                Discount Value:
              </Typography>
              <Typography sx={{ gridColumn: "span 1" }}>
                {couponData?.discountValue}
              </Typography>
              <Typography variant="h6" sx={{ gridColumn: "span 1" }}>
                Minimum Cart Value:
              </Typography>
              <Typography sx={{ gridColumn: "span 1" }}>
                {couponData?.minCartValue}
              </Typography>
            </>
          )}
          <Typography variant="h6" sx={{ gridColumn: "span 1" }}>
            Status:
          </Typography>
          <Typography sx={{ gridColumn: "span 1" }}>
            {couponData?.status}
          </Typography>
          <Typography variant="h6" sx={{ gridColumn: "span 1" }}>
            Discount Type:
          </Typography>
          <Typography sx={{ gridColumn: "span 1" }}>
            {couponData?.discountType}
          </Typography>
          <Typography variant="h6" sx={{ gridColumn: "span 1" }}>
            Expiry:
          </Typography>
          <Typography sx={{ gridColumn: "span 1" }}>
            {formatTimestamp(couponData?.expiry)}
          </Typography>
          {couponData?.discountType === "percentage" && (
            <>
              <Typography variant="h6" sx={{ gridColumn: "span 1" }}>
                Maximum Discount:
              </Typography>
              <Typography sx={{ gridColumn: "span 1" }}>
                {couponData?.maxDiscount}
              </Typography>
            </>
          )}
          <Typography variant="h6" sx={{ gridColumn: "span 1" }}>
            Maximum Uses:
          </Typography>
          <Typography sx={{ gridColumn: "span 1" }}>
            {couponData?.maxUses}
          </Typography>
          <Typography variant="h6" sx={{ gridColumn: "span 1" }}>
            Number Of Uses:
          </Typography>
          <Typography sx={{ gridColumn: "span 1" }}>
            {couponData?.numberOfUses}
          </Typography>
          {couponData?.discountType === "bundle" && (
            <>
              <Typography variant="h4" sx={{ gridColumn: "span 4" }}>
                Bundle Details:
              </Typography>
              <Typography variant="h6" sx={{ gridColumn: "span 1" }}>
                Quantity:
              </Typography>
              <Typography sx={{ gridColumn: "span 1" }}>
                {couponData?.bundleDetails?.quantity}
              </Typography>
              <Typography variant="h6" sx={{ gridColumn: "span 1" }}>
                Price:
              </Typography>
              <Typography sx={{ gridColumn: "span 1" }}>
                {couponData?.bundleDetails?.bundlePrice}
              </Typography>
              <Typography variant="h6" sx={{ gridColumn: "span 1" }}>
                Category:
              </Typography>
              <Typography sx={{ gridColumn: "span 1" }}>
                {couponData?.bundleDetails?.category?.name}
              </Typography>
            </>
          )}

          <Box sx={{ gridColumn: "span 4" }}>
            <Typography variant="h6">Description:</Typography>
            <Typography>{parse(couponData?.description ?? "")}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default View;
