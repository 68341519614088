import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { useGetOrdersQuery } from "services";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "hooks";
import { useState } from "react";
import {
  formatDateTimeLocalMUI,
  formatTimestamp,
  getFormattedDate,
} from "utils/common";
import { useGetDashboardInfoQuery } from "services/utils.service";
import TagIcon from "@mui/icons-material/Tag";
import { CurrencyRupeeOutlined } from "@mui/icons-material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import dataGridStyles from "../../styles/dataGridStyles";
import StyledDataGrid from "components/datagrids/StyledDataGrid";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchName = searchParams.get("search");
  const searchStatus = searchParams.get("status");
  const debouncedNameQry = useDebounce(searchName, 500);
  const [searchState, setSearchState] = useState("");
  const styles = dataGridStyles(theme.palette.mode);
  const navigate = useNavigate();
  const { data: ordersData = [], isLoading } = useGetOrdersQuery(
    {
      params: {
        page: 1,
        limit: 3,
        search: debouncedNameQry ?? undefined,
        status: searchStatus ?? undefined,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: pendingOrders = [] } = useGetOrdersQuery(
    {
      params: {
        page: 1,
        limit: 5,
        search: debouncedNameQry ?? undefined,
        status: "68",
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: dashboardData = {} } = useGetDashboardInfoQuery();
  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    margin: "25px 0 0",
    textAlign: "left",
  };
  const thTdStyle = {
    padding: "12px",
    borderBottom: "1px solid #ddd",
  };

  console.log("ordersData>>>", dashboardData);

  const columns = [
    {
      field: "orderId",
      headerName: "Order ID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => (
        <img
          src={
            params.row?.products[0]?.mainImage ??
            params.row?.products[0]?.productId?.mainImage
          }
          alt={params.row?.product?.name}
          style={{ width: "50px", height: "50px", objectFit: "contain" }}
        />
      ),
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      renderCell: (params) => <span>&#8377; {params?.value}</span>,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => <span>{formatTimestamp(params?.value)}</span>,
    },
    {
      field: "statusText",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.value}>
          <Chip label={params?.value} size="small" variant="outlined" />
        </Tooltip>
      ),
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}

      <Box
        className="dashboard-chips"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          color={colors.blueAccent[700]}
          className="chips"
        >
          <span className="round"></span>
          <StatBox
            title={dashboardData?.ordersCount}
            subtitle="Total Orders"
            progress="0.75"
            increase="+14%"
            icon={
              <TagIcon
                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="chips second"
        >
          <span className="round"></span>
          <StatBox
            title={dashboardData?.productsCount}
            subtitle="Total Products"
            progress="0.50"
            increase="+21%"
            icon={
              <ShoppingCartIcon
                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="chips third"
        >
          <span className="round"></span>
          <StatBox
            title={dashboardData?.usersCount}
            subtitle="Total Users"
            progress="0.30"
            increase="+5%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="chips fourth"
        >
          <span className="round"></span>
          <StatBox
            title={dashboardData?.todaysCollection}
            subtitle="Today's Collection"
            progress="0.80"
            increase="+43%"
            icon={
              <CurrencyRupeeOutlined
                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>

      <Box
        sx={{
          ...styles.formContainer,
          marginBottom: 3,
        }}
      >
        <h3 style={{ margin: 0 }}>Recent Transactions :</h3>
        <Box sx={{ gridColumn: "span 4" }}>
          <table style={tableStyle}>
            <tbody>
              {ordersData?.items?.length &&
                ordersData?.items.map((transaction, i) => (
                  <tr key={`${transaction.orderId}-${i}`}>
                    <td width="20%" style={thTdStyle}>
                      {transaction?.products[0]?.name}
                    </td>
                    <td width="20%" style={thTdStyle}>
                      {" "}
                      {getFormattedDate(transaction.createdAt)}
                    </td>
                    <td width="20%" style={thTdStyle}>
                      {transaction?.products[0]?.selectedColor?.name}
                    </td>
                    <td width="20%" style={thTdStyle}>
                      {" "}
                      {transaction?.products[0]?.selectedSize}
                    </td>
                    <td style={thTdStyle}>₹{transaction.totalPrice}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Box>
      </Box>

      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <h3 style={{ margin: "5px 0" }}>Pending Orders:</h3>
          <Button sx={styles.buttonMd}
          onClick={() => navigate("/orders")}
          >View All Orders</Button>
        </Box>
        <StyledDataGrid
          rows={pendingOrders?.items?.length ? pendingOrders.items : []}
          columns={columns}
          getRowId={(row) => row?._id}
          pagination
          page={0}
          pageSize={5}
          rowCount={5}
          paginationMode="client"
          className="no-shaddow"
          noResultText="No orders found"
          customStyle={{
            height: "250px",
          }}
          containerStyle={{
            height: "300px",
          }}
        />
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
        color={colors.blueAccent[700]}
      >
        {/* <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Transactions
            </Typography>
          </Box>
          {ordersData?.items?.length &&
            ordersData?.items.map((transaction, i) => (
              <Grid
                key={`${transaction.orderId}-${i}`}
                container
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  borderBottom: `4px solid ${colors.primary[500]}`,
                  padding: "15px",
                }}
              >
                <Grid item xs={12} sm={3}>
                  <Typography
                    color={colors.blueAccent[500]}
                    variant="h5"
                    fontWeight="600"
                  >
                    {transaction?.products[0]?.name}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={2} textAlign="left">
                  <Typography color={colors.grey[100]}>
                    {getFormattedDate(transaction.createdAt)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={2} textAlign="left">
                  <Typography color={colors.grey[100]}>
                    {transaction?.products[0]?.selectedColor?.name}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={2} textAlign="left">
                  <Typography color={colors.grey[100]}>
                    {transaction?.products[0]?.selectedSize}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Box
                    backgroundColor={colors.blueAccent[500]}
                    color={colors.whiteAccent[100]}
                    p="5px 10px"
                    borderRadius="4px"
                    textAlign="center"
                  >
                    ₹{transaction.totalPrice}
                  </Box>
                </Grid>
              </Grid>
            ))}
        </Box> */}

        {/* <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Pending Orders
            </Typography>
          </Box>
          {dashboardData?.pendingOrders?.length &&
            dashboardData?.pendingOrders.map((transaction, i) => (
              <Grid
                key={`${transaction.orderId}-${i}`}
                container
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  borderBottom: `4px solid ${colors.primary[500]}`,
                  padding: "15px",
                }}
              >
                <Grid item xs={12} sm={3}>
                  <Typography
                    color={colors.blueAccent[500]}
                    variant="h5"
                    fontWeight="600"
                  >
                    {transaction?.products[0]?.productId?.name}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={2} textAlign="left">
                  <Typography color={colors.grey[100]}>
                    {getFormattedDate(transaction.createdAt)}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={2} textAlign="left">
                  <Typography color={colors.grey[100]}>
                    {transaction?.products[0]?.selectedColor?.name}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={2} textAlign="left">
                  <Typography color={colors.grey[100]}>
                    {transaction?.products[0]?.selectedSize}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={2}>
                  <Box
                    backgroundColor={colors.blueAccent[500]}
                    color={colors.whiteAccent[100]}
                    p="5px 10px"
                    borderRadius="4px"
                    textAlign="center"
                  >
                    ₹{transaction.totalPrice}
                  </Box>
                </Grid>
              </Grid>
            ))}
        </Box> */}

        {/* ROW 3 */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.blueAccent[500]}
              sx={{ mt: "15px" }}
            >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Geography Based Traffic
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
