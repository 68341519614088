import { useActiveToggleRowMutation } from "services/utils.service";

const useActiveToggleRecord = () => {
  // mutation
  const [activeToggleRow] = useActiveToggleRowMutation();

  const activeToggleRecord = async (payload, refetch) => {
    try {
      await activeToggleRow(payload);
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  return { activeToggleRecord };
};

export default useActiveToggleRecord;
