import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from '@mui/icons-material/LockReset';
import { Box, IconButton, Tooltip, useTheme, Button } from "@mui/material";
import Header from "components/Header";
import StyledDataGrid from "components/datagrids/StyledDataGrid";
import ChangePasswordModal from "components/modals/ChangePasswordModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGetSubAdminsQuery } from "services";
import { tokens } from "theme";
import dataGridStyles from "../../styles/dataGridStyles";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const SubAdmins = () => {
  const [show, setShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  // queries
  const { data = [], isLoading } = useGetSubAdminsQuery(
    {
      params: {
        page: page + 1,
        limit: pageSize,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const styles = dataGridStyles(theme.palette.mode);
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.auth.user);

  const columns = [
    {
      field: "firstName",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {params.row.firstName} {params.row.lastName}
          </span>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "contactNumber",
      headerName: "Contact",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Change Password">
            <IconButton
              onClick={() => {
                setSelectedRow(params.row);
                setShow(true);
              }}
            >
              <LockResetIcon />
            </IconButton>
          </Tooltip>
          <Link to={`/sub-admins/edit/${params.row._id}`}>
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box
        sx={{
          ...styles.headingsContainer,
          ...styles.dFlex,
          ...styles.alignItemsStart,
          ...styles.justifyContentBetween,
        }}
      >
        <Box sx={styles.mainHeadings}>
          <Header title="SUB ADMINS" subtitle="Managing the Sub Admins" />
        </Box>
        {userInfo?.roles === "ADMIN" && (
          <Box>
            <Button
              sx={styles.buttonMd}
              LinkComponent={Link}
              to="/sub-admins/add"
            >
              <AddOutlinedIcon sx={{ mr: "10px" }} />
              Add Sub Admin
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          ...styles.formContainer,
        }}
      >
        <StyledDataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row._id}
          loading={isLoading}
          pagination
          page={page}
          pageSize={pageSize}
          rowCount={100}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          className="no-shaddow"
          noResultText="No sub admins found."
        />
      </Box>
      <ChangePasswordModal
        show={show}
        setShow={setShow}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </Box>
  );
};

export default SubAdmins;
