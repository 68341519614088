import { API_ENDPOINTS } from "../constants/endpoints";
import { apiSlice, handleResponse } from "./base.service";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation({
      query: (payload) => ({
        url: API_ENDPOINTS.SIGN_IN,
        method: "POST",
        body: payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    updateProfile: builder.mutation({
      query: (payload) => ({
        url: API_ENDPOINTS.UPDATE_PROFILE,
        method: "PUT",
        body: payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    getProfile: builder.query({
      query: () => ({
        url: API_ENDPOINTS.GET_PROFILE,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    changePassword: builder.mutation({
      query: (payload) => ({
        url: API_ENDPOINTS.CHANGE_PASSWORD,
        method: "POST",
        body: payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    getSubAdmins: builder.query({
      query: () => ({
        url: API_ENDPOINTS.GET_SUB_ADMINS,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    changeSubAdminPassword: builder.mutation({
      query: (payload) => ({
        url: API_ENDPOINTS.CHANGE_SUB_ADMIN_PASSWORD,
        method: "PUT",
        body: payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    addSubAdmin: builder.mutation({
      query: (payload) => ({
        url: API_ENDPOINTS.ADD_SUB_ADMIN,
        method: "POST",
        body: payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    updateSubAdmin: builder.mutation({
      query: ({payload, subAdminId}) => ({
        url: `${API_ENDPOINTS.UPDATE_SUB_ADMIN}/${subAdminId}`,
        method: "PUT",
        body: payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    getSubAdminInfo: builder.query({
      query: (subAdminId) => ({
        url: `${API_ENDPOINTS.GET_SUB_ADMIN_INFO}/${subAdminId}`,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
  }),
});

export const {
  useSignInMutation,
  useUpdateProfileMutation,
  useGetProfileQuery,
  useChangePasswordMutation,
  useChangeSubAdminPasswordMutation,
  useGetSubAdminsQuery,
  useAddSubAdminMutation,
  useUpdateSubAdminMutation,
  useGetSubAdminInfoQuery,
} = authApiSlice;
