import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmModalConfig } from "store/slices/utilSlice";
import dataGridStyles from "../../styles/dataGridStyles";

const ConfirmModal = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const modalConfig = useSelector((state) => state.utils.confirmModalConfig);
  const styles = dataGridStyles(theme.palette.mode);

  const handleClose = () => {
    dispatch(
      setConfirmModalConfig({
        ...modalConfig,
        visible: false,
      })
    );
  };

  const defaultContent = {
    heading: "Delete?",
    description: "Are you sure you want to delete this item?",
  };

  const {
    visible,
    data: { onSubmit, content = defaultContent },
  } = modalConfig;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={visible}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{content?.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content?.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button
          sx={styles.buttonMd}
          onClick={() => {
            onSubmit();
            handleClose();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
