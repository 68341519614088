import { Box, Button, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "components/Header";
import parse from "html-react-parser";
import { useNavigate, useParams } from "react-router-dom";
import { useGetStaticPageQuery } from "services/staticpage.service";
import dataGridStyles from "../../styles/dataGridStyles";

const View = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  // const styles = {
  //   mainHeadings: {
  //     display: 'flex',
  //     justifyContent: 'space-between',
  //     alignItems: 'center',
  //     mb: 3,
  //   },
  //   buttonMd: {
  //     fontSize: '1rem',
  //     padding: '8px 16px',
  //   },
  //   formContainer: {
  //     backgroundColor: theme.palette.background.paper,
  //     padding: '24px',
  //     borderRadius: '8px',
  //     boxShadow: theme.shadows[2],
  //   },
  //   gridItem: {
  //     display: 'flex',
  //     alignItems: 'center',
  //     padding: '8px 0',
  //   },
  //   label: {
  //     fontWeight: 500,
  //     fontSize: '1rem',
  //     color: theme.palette.text.secondary,
  //   },
  //   value: {
  //     fontSize: '1rem',
  //     color: theme.palette.text.primary,
  //     wordBreak: 'break-word',
  //   },
  //   description: {
  //     fontSize: '1rem',
  //     color: theme.palette.text.primary,
  //     mt: 2,
  //   },
  // };

  const { slug: pageSlug } = useParams();
  const { data: pageData } = useGetStaticPageQuery(pageSlug, {
    skip: !pageSlug,
    refetchOnMountOrArgChange: true,
  });

  const navigate = useNavigate();

  return (
    <Box m="20px">
      <Box sx={styles.mainHeadings}>
        <Header title="PAGE DETAILS" />
        <Box display="flex" justifyContent="end" mt="20px">
        <Button
          onClick={() => navigate("/static-pages")}
          color="secondary"
          variant="contained"
          sx={styles.buttonMd}
        >
          Back to Pages
        </Button>
        </Box>
      </Box>
      <Box sx={styles.formContainer}>
        <Box
          display="grid"
          gap="20px"
          gridTemplateColumns="repeat(4, 1fr)"
        >
          <Box sx={{ ...styles.gridItem, gridColumn: "span 2" }}>
            <Typography variant="h6" sx={styles.label}>
              Slug:
            </Typography>
            <Typography sx={styles.value}>
              {pageData?.slug}
            </Typography>
          </Box>

          <Box sx={{ ...styles.gridItem, gridColumn: "span 2" }}>
            <Typography variant="h6" sx={styles.label}>
              Title:
            </Typography>
            <Typography sx={styles.value}>
              {pageData?.title}
            </Typography>
          </Box>

          <Box sx={{ gridColumn: "span 4" }}>
            <Typography variant="h6" sx={styles.label}>
              Description:
            </Typography>
            <Typography sx={styles.description}>
              {parse(pageData?.description ?? "")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default View;
