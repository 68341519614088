import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import dataGridStyles from "../../styles/dataGridStyles";
import CommonModal from "./CommonModal";

const AddStockModal = ({ formik, show, setShow }) => {
  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  const [stockObj, setStockObj] = useState({
    size: "[]",
    minqty: 0,
    unitPrice: 0,
    sellingPrice: 0,
    productType: "B2C",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.setFieldValue("stock", [...formik.values.stock, stockObj]);
    setShow(false);
    setStockObj({
      size: "[]",
      minqty: 0,
      unitPrice: 0,
      sellingPrice: 0,
      productType: "B2C",
    });
  };

  useEffect(() => {
    if (show) {
      setStockObj({
        ...stockObj,
        unitPrice: formik.values.originalPrice,
        sellingPrice: formik.values.sellingPrice,
      });
    }
  }, [show]);

  return (
    <CommonModal open={show} onClose={setShow} title={"Add Stock"}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 2,
          }}
        >
          <Box sx={{ gridColumn: "span 1" }}>
            <Autocomplete
              sx={{ mt: 2 }}
              options={formik.values.isSize ? formik.values.sizeType : []}
              getOptionLabel={(option) => option}
              onBlur={formik.handleBlur}
              onChange={(_, value) => setStockObj({ ...stockObj, size: value })}
              name="color"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  label="Size"
                />
              )}
              className="select"
              renderOption={(props, option) => {
                const isDisabled = formik.values.stock.some(
                  (stockItem) => stockItem.size === option
                );
                return (
                  <li
                    {...props}
                    style={{
                      pointerEvents: isDisabled ? "none" : "auto",
                      opacity: isDisabled ? 0.5 : 1,
                    }}
                  >
                    {option}
                  </li>
                );
              }}
            />
          </Box>
          <TextField
            label="Quantity"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            value={stockObj.minqty || 0}
            onChange={(e) =>
              setStockObj({ ...stockObj, minqty: e.target.value })
            }
            className="input fieldset-1"
          />
          <TextField
            label="Original Price"
            variant="outlined"
            type="number"
            fullWidth
            margin="normal"
            value={stockObj.unitPrice || 0}
            onChange={(e) =>
              setStockObj({ ...stockObj, unitPrice: e.target.value })
            }
            className="input fieldset-1"
          />
          <TextField
            label="Selling Price"
            variant="outlined"
            fullWidth
            type="number"
            margin="normal"
            value={stockObj.sellingPrice || 0}
            onChange={(e) =>
              setStockObj({ ...stockObj, sellingPrice: e.target.value })
            }
            className="input fieldset-1"
          />
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}
        >
          <Button
            type="submit"
            variant=""
            style={{
              backgroundColor: "#846CF9",
              color: "white",
              border: "none",
            }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </CommonModal>
  );
};

export default AddStockModal;
