import {
  Box,
  Button,
  Grid,
  InputLabel,
  Typography,
  useTheme,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "components/Header";
import { GLOBAL_SETTINGS } from "constants/constantData";
import parse from "html-react-parser";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProductsQuery } from "services";
import dataGridStyles from "../../styles/dataGridStyles";
import VideoPlayer from "./VideoPlayer";

const View = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  const { id } = useParams();

  const { data: productData } = useGetProductsQuery(
    { id },
    {
      skip: !id,
    }
  );

  const navigate = useNavigate();

  return (
    <Box m="20px">
      <Box sx={styles.mainHeadings}>
        <Header title="PRODUCT DETAILS" />

        <Box display="flex" justifyContent="end" mt="20px">
          <Button
            onClick={() => navigate("/products")}
            color="secondary"
            variant="contained"
            sx={styles.buttonMd}
          >
            Back to Products
          </Button>
        </Box>
      </Box>
      <Box sx={styles.formContainer}>
        <Grid container sx={styles.viewForm} spacing={2}>
          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">SKU ID:</InputLabel>
              <input
                value={productData?.sku}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Name:</InputLabel>
              <input
                value={productData?.name}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Price:</InputLabel>
              <input
                value={`${parse(GLOBAL_SETTINGS?.CURRENCY)}${
                  productData?.prices?.b2cPrice?.originalPrice || ""
                }`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Selling Price:</InputLabel>
              <input
                value={`${parse(GLOBAL_SETTINGS?.CURRENCY)}${
                  productData?.prices?.b2cPrice?.sellingPrice || ""
                }`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Discount:</InputLabel>
              <input
                value={`${productData?.prices?.b2cPrice?.discount || ""}%`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Shop Name:</InputLabel>
              <input
                value={`${productData?.shop?.name || ""}`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Shop Address:</InputLabel>
              <input
                value={`${productData?.shop?.location || ""}`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Brand:</InputLabel>
              <input
                value={`${productData?.shop?.brand || ""}`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Category:</InputLabel>
              <input
                value={`${productData?.categoryId?.name || ""}`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Sections:</InputLabel>
              <input
                value={`${productData?.attributes?.sections?.join(", ") || ""}`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Discount Label:</InputLabel>
              <input
                value={`${productData?.attributes?.discount || ""}`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Occasion:</InputLabel>
              <input
                value={`${productData?.attributes?.occasion?.join(", ") || ""}`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Fabric:</InputLabel>
              <input
                value={`${productData?.attributes?.fabric?.join(", ") || ""}`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Length:</InputLabel>
              <input
                value={`${productData?.attributes?.length || ""}`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Color:</InputLabel>
              <input
                value={`${
                  productData?.attributes?.color
                    ?.map((col) => col.name)
                    .join(", ") || ""
                }`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Pattern:</InputLabel>
              <input
                value={`${productData?.attributes?.pattern || ""}`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Tags:</InputLabel>
              <input
                value={`${productData?.attributes?.tags?.join(", ") || ""}`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">
                {" "}
                Size Available:
              </InputLabel>
              <input
                value={`${productData?.isSize ? "Yes" : "No"}`}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          {productData?.isSize && (
            <Grid item lg={6}>
              <Box className="d-flex mb-15">
                <InputLabel className="control-label"> Size Type:</InputLabel>
                <input
                  value={
                    productData?.sizeType?.length > 0
                      ? productData?.sizeType.join(", ")
                      : "All"
                  }
                  disabled
                  className="form-control"
                />
              </Box>
            </Grid>
          )}

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label"> Description:</InputLabel>
              <input
                value={parse(productData?.description ?? "")}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">
                {" "}
                Additional Information:
              </InputLabel>
              <input
                value={parse(productData?.additionalInformation ?? "")}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label"> Keywords:</InputLabel>
              <input
                value={productData?.seo?.keywords.join(", ")}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label"> Meta Title:</InputLabel>
              <input
                value={productData?.seo?.metaTitle}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">
                {" "}
                Meta Description:
              </InputLabel>
              <textarea
                value={productData?.seo?.metaDescription}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>
        </Grid>

        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
        >
          <Box sx={{ gridColumn: "span 4" }}>
            <Typography variant="h4">Stocks:</Typography>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                margin: "25px 0",
                textAlign: "left",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{ padding: "12px", borderBottom: "1px solid #ddd" }}
                    className="custom-table-header"
                  >
                    Size
                  </th>
                  <th
                    style={{ padding: "12px", borderBottom: "1px solid #ddd" }}
                  >
                    Quantity
                  </th>
                  <th
                    style={{ padding: "12px", borderBottom: "1px solid #ddd" }}
                  >
                    Unit Price
                  </th>
                  <th
                    style={{ padding: "12px", borderBottom: "1px solid #ddd" }}
                  >
                    Selling Price
                  </th>
                  <th
                    style={{ padding: "12px", borderBottom: "1px solid #ddd" }}
                  >
                    Product Type
                  </th>
                </tr>
              </thead>
              <tbody>
                {productData?.stock.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        padding: "12px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      {item?.size}
                    </td>
                    <td
                      style={{
                        padding: "12px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      {item?.minqty}
                    </td>
                    <td
                      style={{
                        padding: "12px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      {item?.unitPrice}
                    </td>
                    <td
                      style={{
                        padding: "12px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      {item?.sellingPrice}
                    </td>
                    <td
                      style={{
                        padding: "12px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      {item?.productType}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>

          {productData?.attributes?.color?.length > 0 && (
            <Box sx={{ gridColumn: "span 4" }}>
              <Typography variant="h4">Colors:</Typography>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  margin: "25px 0",
                  textAlign: "left",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "12px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      Name
                    </th>
                    <th
                      style={{
                        padding: "12px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      Hex Code
                    </th>
                    <th
                      style={{
                        padding: "12px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      Main Image
                    </th>
                    <th
                      style={{
                        padding: "12px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      Other Images
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {productData?.attributes?.color.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          padding: "12px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {item.name}
                      </td>
                      <td
                        style={{
                          padding: "12px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {item.hexCode}
                      </td>
                      <td
                        style={{
                          padding: "12px",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {item.images.mainImage.mediaType === "video" ? (
                          <VideoPlayer src={item.images.mainImage.url} />
                        ) : (
                          <img
                            src={item.images.mainImage.url}
                            style={{ width: "100px" }}
                          />
                        )}
                      </td>
                      <td
                        style={{
                          padding: "12px",
                          borderBottom: "1px solid #ddd",
                          overflow: "auto",
                        }}
                      >
                        <Box sx={{ display: "flex", columnGap: "8px" }}>
                          {item?.images?.images?.length > 0 &&
                            item.images.images.map((image) => {
                              return (
                                <>
                                  {image.mediaType === "video" ? (
                                    <VideoPlayer src={image.url} />
                                  ) : (
                                    <img
                                      src={image.url}
                                      style={{ width: "100px" }}
                                    />
                                  )}
                                </>
                              );
                            })}
                        </Box>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default View;
