import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, Grid, useTheme, TextField } from "@mui/material";
import React from "react";
import Header from "components/Header";
import { tokens } from "theme";
import { Link } from "react-router-dom";
import dataGridStyles from "../../styles/dataGridStyles";
import FileUploadProgress from "components/FileUploadProgress";
import profileImge from "../../assets/14.jpg";
import { useGetProfileQuery, useUpdateProfileMutation } from "services";
import { useFormik } from "formik";
import {
  adminProfileInitialValues,
  profileValidationSchema,
} from "constants/schemas";
import { toast } from "react-toastify";
import { setUserData } from "store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { preFillValues } from "utils/common";

const Profile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const styles = dataGridStyles(theme.palette.mode);
  const { data: userSavedData = {} } = useGetProfileQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  
  const [updateProfile] = useUpdateProfileMutation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: preFillValues(adminProfileInitialValues, userSavedData),
    validationSchema: profileValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const result = await updateProfile(values);
        if (result?.data?._id) {
          dispatch(
            setUserData({
              ...userData,
              user: result?.data,
            })
          );
          toast.success("Profile updated successfully!");
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <Box m="20px">
      <Box
        sx={{
          ...styles.headingsContainer,
          ...styles.dFlex,
          ...styles.alignItemsStart,
          ...styles.justifyContentBetween,
        }}
      >
        <Box sx={styles.mainHeadings}>
          <Header title="Profile" subtitle="Manage your profile" />
        </Box>
      </Box>

      <Box sx={{ ...styles.boxContainer, ...styles.formContainer }}>
        {/* <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", columnGap: "30px" }}>
          <Box className="profile-image-box" gap="30px">
            <img src={profileImge} alt="" />
          </Box>
          <Box sx={{ maxWidth: "400px", minWidth: "400px",  }}>
            <TextField
              fullWidth
              variant="filled"
              type="file"
              name="mainImage"
              sx={{ gridColumn: "span 4" }}
              className="input"
              InputProps={{
                accept: "image/*",
                endAdornment: (
                  <FileUploadProgress progress={"uploadProgress?.mainImage"} />
                ),
              }}
              InputLabelProps={{ shrink: true }}
              label="Profile Image"
            />
          </Box>
        </Box> */}
        <form onSubmit={formik.handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="First Name"
              name="firstName"
              sx={{ gridColumn: "span 2" }}
              className="input"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Last Name"
              name="lastName"
              sx={{ gridColumn: "span 2" }}
              className="input"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="email"
              label="Email"
              name="email"
              sx={{ gridColumn: "span 2" }}
              className="input"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Contact Number"
              name="contactNumber"
              sx={{ gridColumn: "span 2" }}
              className="input"
              value={formik.values.contactNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contactNumber &&
                Boolean(formik.errors.contactNumber)
              }
              helperText={
                formik.touched.contactNumber && formik.errors.contactNumber
              }
              InputLabelProps={{ shrink: true }}
            />
            <Box sx={{ textAlign: "right", gridColumn: "span 2" }}>
              <Button
                color="secondary"
                variant="contained"
                sx={styles.buttonMd}
                type="submit"
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Profile;
