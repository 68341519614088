import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  discountInitialValues,
  discountValidationSchema,
} from "constants/schemas/masters";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useAddDiscountMutation, useEditDiscountMutation } from "services";
import { setShowMasterModal } from "store/slices/utilSlice";

import { useTheme } from "@mui/material";
import dataGridStyles from "../../../styles/dataGridStyles";
import { preFillValues } from "utils/common";

const DiscountContent = () => {
  const dispatch = useDispatch();
  // mutations
  const [discountMutation] = useAddDiscountMutation();
  const [editDiscountMutation] = useEditDiscountMutation();
 
  // store
  const selectedModelContent = useSelector(
    (state) => state.utils.selectedModelContent
  );

  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);

  const formik = useFormik({
    initialValues: preFillValues(discountInitialValues, selectedModelContent),
    validationSchema: discountValidationSchema,
    onSubmit: (values) => {
      if (selectedModelContent?._id) {
        editDiscountMutation({
          payload: values,
          id: selectedModelContent._id,
        });
      } else {
        discountMutation(values);
      }
      dispatch(setShowMasterModal(false));
    },
  });
  return (
    <Box sx={{ ...styles.formContainer, ...styles.modalContainer, }}>
      <Typography variant="h6" gutterBottom>
        {selectedModelContent?._id ? "Edit Discount" : "Add New Discount"}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          name="name"
          error={formik.touched.name && Boolean(formik.errors.name)}
          // className="input"
        />
        <TextField
          type="number"
          label="Value"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.value}
          name="value"
          error={formik.touched.value && Boolean(formik.errors.value)}
          className="input fieldset-1"
        />
        <TextField
          type="number"
          label="Sort Order"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.sortOrder}
          name="sortOrder"
          error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)}
          className="input fieldset-1"
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default DiscountContent;
