import { API_ENDPOINTS } from "../constants/endpoints";
import { apiSlice, handleResponse } from "./base.service";

export const productApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: ({id, params}) => ({
        url: id ? `${API_ENDPOINTS.GET_PRODUCTS}/${id}` : API_ENDPOINTS.GET_PRODUCTS,
        method: "GET",
        params
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),

    createProduct: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.CREATE_PRODUCT,
        method: "POST",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    editProduct: builder.mutation({
      query: (payload) => ({
        url: `${API_ENDPOINTS.EDIT_PRODUCT}/${payload?.id}`,
        method: "PUT",
        body: payload?.body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
  }),
});

export const { useCreateProductMutation, useGetProductsQuery, useEditProductMutation } =
  productApiSlice;
