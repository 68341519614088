import "./Signin.scss"; // Importing the SCSS file
import { Box, Button, Container, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { signInInitialValues, signInValidationSchema } from "constants/schemas";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSignInMutation } from "services";
import { setUserData } from "store/slices/authSlice";
import logo from "../../../assets/admin-logo.png"; // Add the correct path to your logo image
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

const SignIn = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  // mutation
  const [signIn] = useSignInMutation();

  const formik = useFormik({
    initialValues: signInInitialValues,
    validationSchema: signInValidationSchema,
    onSubmit: (values) => {
      signIn(values)
        .unwrap()
        .then((result) => {
          if (result?.token) {
            dispatch(setUserData(result));
            navigate("/");
          }
        });
    },
  });

  return (
    <div id="login-page">
      <Container component="main" maxWidth="xs" className="signin-container">
        <Box className="logo-box">
          <img src={logo} alt="Logo" className="signin-logo" />
        </Box>
        <Typography component="h1" variant="h5" className="signin-title">
          <span>{import.meta.env.VITE_PANEL_TITLE}</span> <br />Admin Panel
        </Typography>
        <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
          <TextField
            autoComplete="off"
            fullWidth
            margin="normal"
            id="email"
            name="email"
            label="Email Address"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputLabelProps={{ shrink: true }}
            className="signin-input"
          />
          {/* <TextField
            fullWidth
            margin="normal"
            id="password"
            name="password"
            label="Password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputLabelProps={{ shrink: true }}
            className="signin-input"
          /> */}

          <TextField
            className="signin-input"
            fullWidth
            margin="normal"
            id="password"
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            className="signin-button"
            color="primary"
          >
            Sign In
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default SignIn;
