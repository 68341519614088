import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  captionTagInitialValues,
  captionTagValidationSchema,
} from "constants/schemas/masters";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useAddCaptionTagMutation, useEditCaptionTagMutation } from "services";
import { setShowMasterModal } from "store/slices/utilSlice";
import { useTheme } from "@mui/material";
import dataGridStyles from "../../../styles/dataGridStyles";
import { preFillValues } from "utils/common";

const CaptionTagContent = () => {
  const dispatch = useDispatch();
  // mutations
  const [captionTagMutation] = useAddCaptionTagMutation();
  const [editCaptionTagMutation] = useEditCaptionTagMutation();
  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
   // store
   const selectedModelContent = useSelector(
    (state) => state.utils.selectedModelContent
  );

  const formik = useFormik({
    initialValues: preFillValues(captionTagInitialValues, selectedModelContent),
    validationSchema: captionTagValidationSchema,
    onSubmit: (values) => {
      if (selectedModelContent?._id) {
        editCaptionTagMutation({
          payload: values,
          id: selectedModelContent._id,
        });
      } else {
        captionTagMutation(values);
      }
      dispatch(setShowMasterModal(false));
    },
  });
  return (
    <Box sx={{ ...styles.formContainer, ...styles.modalContainer, }}>
      <Typography variant="h6"  gutterBottom>
       {selectedModelContent?._id ? "Edit Caption Tag" : "Add New Caption Tag"}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          name="name"
          error={formik.touched.name && Boolean(formik.errors.name)}
          // className="input"
        />
        <TextField
          type="number"
          label="Sort Order"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.sortOrder}
          name="sortOrder"
          error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)}
          className="input fieldset-1"
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default CaptionTagContent;
