import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FileUploadProgress from "components/FileUploadProgress";
import {
  patternInitialValues,
  patternValidationSchema,
} from "constants/schemas/masters";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAddPatternMutation, useEditPatternMutation } from "services";
import { setShowMasterModal } from "store/slices/utilSlice";
import { handleFileUpload, preFillValues } from "utils/common";
import { useTheme } from "@mui/material";
import dataGridStyles from "../../../styles/dataGridStyles";

const PatternsContent = () => {
  const dispatch = useDispatch();
  // state
  const [uploadProgress, setUploadProgress] = useState(0);

  // store
  const selectedModelContent = useSelector(
    (state) => state.utils.selectedModelContent
  );

  // mutations
  const [patternMutation] = useAddPatternMutation();
  const [editPatternMutation] = useEditPatternMutation();
  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);

  const formik = useFormik({
    initialValues: preFillValues(patternInitialValues, selectedModelContent),
    validationSchema: patternValidationSchema,
    onSubmit: (values) => {
      if (selectedModelContent?._id) {
        editPatternMutation({
          payload: values,
          id: selectedModelContent._id,
        });
      } else {
        patternMutation(values);
      }
      dispatch(setShowMasterModal(false));
    },
  });

  const handleFileChange = (evt) => {
    const file = evt.target.files[0];
    if (file) {
      handleFileUpload(
        file,
        "masters",
        (progress) => {
          setUploadProgress(progress);
        },
        (url) => {
          formik.setFieldValue("image", url || "");
        }
      );
    }
  };
  return (
    <Box sx={{ ...styles.formContainer, ...styles.modalContainer, }}>
      <Typography variant="h6" gutterBottom>
        {selectedModelContent?._id ? "Edit Pattern" : "Add New Pattern"}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          name="name"
          error={formik.touched.name && Boolean(formik.errors.name)}
        />
        {/* <TextField
          fullWidth
          type="file"
          onBlur={formik.handleBlur}
          onChange={handleFileChange}
          name="image"
          error={!!formik.errors.image}
          helperText={formik.errors.image}
          sx={{ gridColumn: "span 2" }}
          InputProps={{
            accept: "image/*",
            endAdornment: <FileUploadProgress progress={uploadProgress} />,
          }}
          InputLabelProps={{ shrink: true }}
          className="input"
        />
        {formik.values.image && (
          <img
            src={formik.values.image}
            alt=""
            style={{ width: "100px", height: "100px" }}
          />
        )} */}
        <TextField
          type="number"
          label="Sort Order"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.sortOrder}
          name="sortOrder"
          error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)}
          className="input fieldset-1"
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default PatternsContent;
