import { Route, Routes } from "react-router-dom";
import ProtectedLayout from "layout/ProtectedLayout";
import UnProtectedLayout from "layout/UnProtectedLayout";
import ProtectedRoutes from "./ProtectedRoutes";
import UnProtectedRoutes from "./UnProtectedRoutes";
import { Unauthorized } from "scenes";

const SiteRoutes = () => {
  return (
    <Routes>
      <Route path="/">
        <Route
          element={
            <UnProtectedLayout data={{ name: "KURTI", occupation: "" }} />
          }
        >
          {UnProtectedRoutes()}
        </Route>
        <Route
          element={<ProtectedLayout data={{ name: "KURTI", occupation: "" }} />}
        >
          {ProtectedRoutes()}
        </Route>
      </Route>
      <Route path="*" element={<center>404</center>} />
      <Route path="unauthorized" element={<Unauthorized />} />
    </Routes>
  );
};

export default SiteRoutes;
