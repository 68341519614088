import { API_ENDPOINTS } from "../constants/endpoints";
import { apiSlice, handleResponse } from "./base.service";

export const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (params) => ({
        url: API_ENDPOINTS.GET_CATEGORIES,
        method: "GET",
        params,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),

    getSubCategories: builder.query({
      query: (params) => ({
        url: API_ENDPOINTS.GET_CATEGORIES,
        method: "GET",
        params,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),

    createCategory: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.CREATE_CATEGORY,
        method: "POST",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),

    updateCategory: builder.mutation({
      query: (payload) => ({
        url: `${API_ENDPOINTS.UPDATE_CATEGORY}/${payload?.id}`,
        method: "PUT",
        body: payload?.body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),

    getCategoryInfo: builder.query({
      query: (id) => `${API_ENDPOINTS.GET_CATEGORIES}/${id}`,
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
  }),
});

export const {
  useCreateCategoryMutation,
  useGetCategoriesQuery,
  useGetCategoryInfoQuery,
  useUpdateCategoryMutation,
  useGetSubCategoriesQuery
} = categoryApiSlice;
