import { createSlice } from "@reduxjs/toolkit";

const utilSlice = createSlice({
  name: "utils",
  initialState: {
    showMasterModal: false,
    selectedModelContent: {},
    confirmModalConfig: {
      visible: false,
      data: {},
    },
  },
  reducers: {
    setShowMasterModal: (state, action) => {
      state.showMasterModal = action.payload;
    },
    setSelectedModelContent: (state, action) => {
      state.selectedModelContent = action.payload;
    },
    setConfirmModalConfig: (state, action) => {
      console.log(action)
      state.confirmModalConfig = action.payload;
    },
  },
});

export const {
  setShowMasterModal,
  setSelectedModelContent,
  setConfirmModalConfig,
} = utilSlice.actions;

export default utilSlice.reducer;
