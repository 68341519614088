import { CheckCircleOutline } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  TextField,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import FileUploadProgress from "components/FileUploadProgress";
import Header from "components/Header";
import {
  categoryInitialValues,
  categoryValidationSchema,
} from "constants/schemas";
import { useFormik } from "formik";
import { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateCategoryMutation,
  useGetCategoriesQuery,
  useGetCategoryInfoQuery,
  useUpdateCategoryMutation,
} from "services";
import { handleFileUpload } from "utils/common";
import dataGridStyles from "../../styles/dataGridStyles";

const Add = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const { id: categoryId } = useParams();

  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  // hooks
  const navigate = useNavigate();

  // state
  const [uploadProgress, setUploadProgress] = useState(0);

  // query
  const { data: categories } = useGetCategoriesQuery();
  const { data: categoryData } = useGetCategoryInfoQuery(categoryId, {
    skip: !categoryId,
    refetchOnMountOrArgChange: true,
  });

  // mutation
  const [createCategory] = useCreateCategoryMutation();
  const [updateCategory] = useUpdateCategoryMutation();

  const savedCategoryValues = {
    name: categoryData?.name || "",
    parentId: categoryData?.parentId || undefined,
    image: categoryData?.image || "",
    description: categoryData?.description || "",
  };

  const formik = useFormik({
    initialValues: { ...categoryInitialValues, ...savedCategoryValues },
    enableReinitialize: true,
    validationSchema: categoryValidationSchema,
    onSubmit: (values) => {
      if (categoryId) {
        updateCategory({
          id: categoryId,
          body: values,
        })
          .unwrap()
          .then((result) => {
            if (result?._id) {
              setUploadProgress(0);
              navigate("/categories");
            }
          });
      } else {
        createCategory(values)
          .unwrap()
          .then((result) => {
            if (result?._id) {
              formik.resetForm();
              setUploadProgress(0);
              navigate("/categories");
            }
          });
      }
    },
  });

  const handleFileChange = (evt) => {
    const file = evt.target.files[0];
    if (file) {
      handleFileUpload(
        file,
        "categories",
        (progress) => {
          setUploadProgress(progress);
        },
        (url) => {
          formik.setFieldValue("image", url || "");
        }
      );
    }
  };

  const filteredCategories = useMemo(() => {
    if (categories?.length) {
      return categories.filter((item) => item._id !== categoryId);
    }
  }, [categories]);

  return (
    <Box m="20px">
      <Box sx={styles.mainHeadings}>
        <Header
          title={`${categoryId ? "EDIT" : "CREATE"} CATEGORY`}
          subtitle={`${categoryId ? "Edit Category" : "Create a New Category"}`}
        />
        {categoryId && (
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              onClick={() => navigate("/categories")}
              color="secondary"
              variant="contained"
              sx={styles.buttonMd}
            >
              Back to Categories
            </Button>
          </Box>
        )}
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            ...styles.formContainer,
          }}
          className="extra-space"
        >
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
              },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              name="name"
              error={!!formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              sx={{ gridColumn: "span 4" }}
              className="input"
            />
            <Autocomplete
              options={filteredCategories}
              getOptionLabel={(option) => option.name}
              onBlur={formik.handleBlur}
              className="autocomplete"
              onChange={(_, value) =>
                formik.setFieldValue("parentId", value?._id)
              }
              name="parentId"
              sx={{ gridColumn: "span 4" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="filled"
                  label="Parent Category (If Any)"
                />
              )}
              value={
                filteredCategories?.length > 0
                  ? filteredCategories?.find(
                      (cat) => cat._id === formik.values.parentId
                    )
                  : null
              }
            />
            <TextField
              fullWidth
              variant="filled"
              type="file"
              onBlur={formik.handleBlur}
              onChange={handleFileChange}
              name="image"
              error={!!formik.errors.image}
              helperText={formik.errors.image}
              sx={{ gridColumn: "span 4" }}
              InputProps={{
                accept: "image/*",
                endAdornment: <FileUploadProgress progress={uploadProgress} />,
              }}
              InputLabelProps={{ shrink: true }}
              className="input"
            />
            {formik.values.image && (
              <img
                src={formik.values.image}
                alt="Main"
                sx={{ gridColumn: "span 4" }}
                width={100}
                height={100}
              />
            )}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
              name="description"
              error={
                !!formik.touched.description && !!formik.errors.description
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              sx={{ gridColumn: "span 4" }}
              className="input"
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={styles.buttonMd}
            >
              {categoryData ? "Update Category" : "Create Category"}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Add;
