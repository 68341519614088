import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadProgress from "components/FileUploadProgress";
import { handleFileUpload } from "utils/common";
import dataGridStyles from "../../styles/dataGridStyles";
import CommonModal from "./CommonModal";
import { PlayArrow } from "@mui/icons-material";
import VideoPlayer from "scenes/products/VideoPlayer";
import { toast } from "react-toastify";

const AddColorStockModal = ({ formik, show, setShow, colorsData }) => {
  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  const [uploadProgress, setUploadProgress] = useState({
    mainImage: 0,
    images: 0,
  });
  const [colorObj, setColorObj] = useState({
    name: "",
    hexCode: "",
    images: {
      mainImage: {
        url: "",
        mediaType: "image",
      },
      images: [],
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    formik.setFieldValue("color", [...formik.values.color, colorObj]);
    setShow(false);
    setColorObj({
      name: "",
      hexCode: "",
      images: {
        mainImage: {
          url: "",
          mediaType: "image",
        },
        images: [],
      },
    });
  };

  const handleFileChange = (evt, type) => {
    const files = Array.from(evt.target.files);
    const newFiles = files.map((file) => {
      const fileName = `${Date.now()}_${file.name}`;
      return new File([file], fileName, { type: file.type });
    });

    newFiles.forEach((newFile) => {
      handleFileUpload(
        newFile,
        "products",
        (progress) => {
          setUploadProgress((prevUploadProgress) => ({
            ...prevUploadProgress,
            [type]: progress,
          }));
        },
        (url) => {
          if (type === "mainImage" && url) {
            setColorObj((prevColorObj) => ({
              ...prevColorObj,
              images: {
                ...prevColorObj.images,
                mainImage: {
                  url,
                  mediaType: newFile.type.includes("video") ? "video" : "image",
                },
              },
            }));
          } else if (type === "images" && url) {
            setColorObj((prevColorObj) => ({
              ...prevColorObj,
              images: {
                ...prevColorObj.images,
                images: [
                  ...prevColorObj.images.images,
                  {
                    url,
                    mediaType: newFile.type.includes("video") ? "video" : "image",
                  },
                ],
              },
            }));
          }
        }
      );
    });
  };

  const handleRemoveImage = (idx) => {
    const newImages = colorObj.images.images.filter((_, index) => index !== idx);
    setColorObj({
      ...colorObj,
      images: {
        ...colorObj.images,
        images: newImages.map(image => ({
          url: image.url || "",
          mediaType: image.mediaType || ""
        })),
      },
    });
  };

  return (
    <CommonModal open={show} onClose={setShow} title={"Add Color Data"}>
      <form onSubmit={handleSubmit}>
        <Autocomplete
          options={colorsData}
          getOptionLabel={(option) => option.name}
          onBlur={formik.handleBlur}
          onChange={(_, value) =>
            setColorObj({
              ...colorObj,
              name: value?.name,
              hexCode: value?.hexCode,
            })
          }
          name="color"
          sx={{ my: 2 }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label="Color"
              InputLabelProps={{ shrink: true }}
            />
          )}
          renderOption={(props, option) => {
            const isDisabled = formik.values.color.some(
              (colorItem) => colorItem.name === option.name
            );
            return (
              <li
                {...props}
                style={{
                  pointerEvents: isDisabled ? "none" : "auto",
                  opacity: isDisabled ? 0.5 : 1,
                }}
              >
                {option?.name}
              </li>
            );
          }}
        />
        <TextField
          fullWidth
          variant="outlined"
          type="file"
          onChange={(evt) => {
            if (evt.target.files[0].type.includes("image")) {
              handleFileChange(evt, "mainImage")
            } else {
              toast.error("Please upload a valid image file");
            }
          }}
          name="mainImage"
          sx={{ my: 2 }}
          InputProps={{
            accept: "image/*",
            endAdornment: (
              <FileUploadProgress progress={uploadProgress?.mainImage} />
            ),
          }}
          InputLabelProps={{ shrink: true }}
          label="Main Image"
        />
        {colorObj?.images?.mainImage?.url && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 2,
            }}
          >
            <img
              src={colorObj?.images?.mainImage?.url}
              alt="product"
              style={{ height: "100px", borderRadius: 4 }}
            />
          </Box>
        )}
        <TextField
          fullWidth
          variant="outlined"
          type="file"
          onChange={(evt) => handleFileChange(evt, "images")}
          name="image"
          sx={{ my: 2 }}
          InputProps={{
            accept: "image/*",
            endAdornment: (
              <FileUploadProgress progress={uploadProgress?.images} />
            ),
          }}
          InputLabelProps={{ shrink: true }}
          label="Images/Videos"
          inputProps={{
            multiple: true,
          }}
        />
        <Box
          sx={{
            display: "flex",
            gap: 2,
            overflow: "auto",
            flexWrap: "wrap",
            justifyContent: "center",
            my: 2,
          }}
        >
          {colorObj?.images?.images?.length > 0 &&
            colorObj.images.images.map((image, idx) => (
              <Box
                key={idx}
                sx={{
                  position: "relative",
                  // width: "100px",
                  height: "100px",
                  marginBottom: 2,
                }}
              >
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    background: "rgba(255, 255, 255, 0.7)",
                    borderRadius: "50%",
                    minWidth: "auto",
                    padding: 0.5,
                    width: 20,
                    height: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 100,
                    "&:hover": {
                      background: "rgba(255, 255, 255, 0.9)",
                    },
                  }}
                  onClick={() => handleRemoveImage(idx)}
                >
                  <CloseIcon />
                </IconButton>
                {image.mediaType === "image" ? (
                  <img
                    src={image.url}
                    alt="product"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: 4,
                    }}
                  />
                ) : (
                  <VideoPlayer src={image.url} />
                )}
              </Box>
            ))}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            type="submit"
            variant=""
            style={{
              backgroundColor: "#846CF9",
              color: "white",
              border: "none",
            }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </CommonModal>
  );
};

export default AddColorStockModal;
