import { Route } from "react-router-dom";
import {
  AddCategory,
  AddCoupon,
  AddProduct,
  AddStaticPage,
  AddSubAdmin,
  Brands,
  CaptionTags,
  Categories,
  ClothLength,
  Colors,
  Coupons,
  Discount,
  Fabric,
  GeneralSetting,
  Occasions,
  Orders,
  Patterns,
  PaymentModes,
  Products,
  Sections,
  SizeTypes,
  StaticPages,
  SubAdmins,
  Users,
  ViewCategory,
  ViewCoupon,
  ViewOrder,
  ViewProduct,
  ViewStaticPage,
} from "../scenes";
import Dashboard from "../scenes/dashboard";
import Profile from "../scenes/profile";
import RoleGuard from "./RoleGuard";
import { ChangePassword } from "scenes/auth/ChangePassword";

const ProtectedRoutes = () => {
  return (
    <>
      {/* Routes accessible by ADMIN and SUBADMIN */}
      <Route
        path="/dashboard"
        element={
          <RoleGuard allowedRoles={["ADMIN", "SUBADMIN"]}>
            <Dashboard />
          </RoleGuard>
        }
      />
      <Route
        path="/change-password"
        element={
          <RoleGuard allowedRoles={["ADMIN", "SUBADMIN"]}>
            <ChangePassword />
          </RoleGuard>
        }
      />

      <Route
        path="/categories"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <Categories />
          </RoleGuard>
        }
      />
      <Route
        path="/categories/add"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <AddCategory />
          </RoleGuard>
        }
      />
      <Route
        path="/categories/edit/:id"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <AddCategory />
          </RoleGuard>
        }
      />
      <Route
        path="/categories/:id"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <ViewCategory />
          </RoleGuard>
        }
      />
      <Route
        path="/products"
        element={
          <RoleGuard allowedRoles={["ADMIN", "SUBADMIN"]}>
            <Products />
          </RoleGuard>
        }
      />
      <Route
        path="/products/add"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <AddProduct />
          </RoleGuard>
        }
      />
      <Route
        path="/products/edit/:id"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <AddProduct />
          </RoleGuard>
        }
      />
      <Route
        path="/products/:id"
        element={
          <RoleGuard allowedRoles={["ADMIN", "SUBADMIN"]}>
            <ViewProduct />
          </RoleGuard>
        }
      />
      <Route
        path="/size-types"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <SizeTypes />
          </RoleGuard>
        }
      />
      <Route
        path="/caption-tag"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <CaptionTags />
          </RoleGuard>
        }
      />
      <Route
        path="/cloth-length"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <ClothLength />
          </RoleGuard>
        }
      />
      <Route
        path="/colors"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <Colors />
          </RoleGuard>
        }
      />
      <Route
        path="/discount"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <Discount />
          </RoleGuard>
        }
      />
      <Route
        path="/fabric"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <Fabric />
          </RoleGuard>
        }
      />
      <Route
        path="/occasions"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <Occasions />
          </RoleGuard>
        }
      />
      <Route
        path="/patterns"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <Patterns />
          </RoleGuard>
        }
      />
      <Route
        path="/brands"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <Brands />
          </RoleGuard>
        }
      />
      <Route
        path="/sections"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <Sections />
          </RoleGuard>
        }
      />
      <Route
        path="/orders"
        element={
          <RoleGuard allowedRoles={["ADMIN", "SUBADMIN"]}>
            <Orders />
          </RoleGuard>
        }
      />
      <Route
        path="/orders/:id"
        element={
          <RoleGuard allowedRoles={["ADMIN", "SUBADMIN"]}>
            <ViewOrder />
          </RoleGuard>
        }
      />
      <Route
        path="/profile"
        element={
          <RoleGuard allowedRoles={["ADMIN", "SUBADMIN"]}>
            <Profile />
          </RoleGuard>
        }
      />
      <Route
        path="/static-pages"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <StaticPages />
          </RoleGuard>
        }
      />
      <Route
        path="/static-pages/add"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <AddStaticPage />
          </RoleGuard>
        }
      />
      <Route
        path="/static-pages/:slug"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <ViewStaticPage />
          </RoleGuard>
        }
      />
      <Route
        path="/static-pages/edit/:slug"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <AddStaticPage />
          </RoleGuard>
        }
      />
      <Route
        path="/general-setting"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <GeneralSetting />
          </RoleGuard>
        }
      />
      <Route
        path="/coupons"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <Coupons />
          </RoleGuard>
        }
      />
      <Route
        path="/coupons/add"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <AddCoupon />
          </RoleGuard>
        }
      />
      <Route
        path="/coupons/:id"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <ViewCoupon />
          </RoleGuard>
        }
      />
      <Route
        path="/coupons/edit/:id"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <AddCoupon />
          </RoleGuard>
        }
      />
      <Route
        path="/sub-admins"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <SubAdmins />
          </RoleGuard>
        }
      />
      <Route
        path="/sub-admins/add"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <AddSubAdmin />
          </RoleGuard>
        }
      />
      <Route
        path="/sub-admins/edit/:id"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <AddSubAdmin />
          </RoleGuard>
        }
      />
      <Route
        path="/users"
        element={
          <RoleGuard allowedRoles={["ADMIN"]}>
            <Users />
          </RoleGuard>
        }
      />
    </>
  );
};

export default ProtectedRoutes;
