import { Box, Button, TextField, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "components/Header";
import {
  staticPageInitialValues,
  staticPageValidationSchema,
} from "constants/schemas";
import { useFormik } from "formik";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCategoriesQuery } from "services";
import dataGridStyles from "../../styles/dataGridStyles";
import {
  useCreateStaticPageMutation,
  useGetStaticPageQuery,
  useUpdateStaticPageMutation,
} from "services/staticpage.service";

const Add = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const { slug: pageSlug } = useParams();

  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  // hooks
  const navigate = useNavigate();

  // query
  const { data: pageData } = useGetStaticPageQuery(pageSlug, {
    skip: !pageSlug,
    refetchOnMountOrArgChange: true,
  });

  // mutation
  const [createStaticPage] = useCreateStaticPageMutation();
  const [updateStaticPage] = useUpdateStaticPageMutation();

  const savedData = {
    title: pageData?.title,
    slug: pageData?.slug,
    description: pageData?.description,
  };

  const formik = useFormik({
    initialValues: { ...staticPageInitialValues, ...savedData },
    enableReinitialize: true,
    validationSchema: staticPageValidationSchema,
    onSubmit: (values) => {
      if (pageSlug) {
        updateStaticPage({
          body: values,
          slug: pageSlug,
        })
          .unwrap()
          .then((result) => {
            if (result?._id) {
              navigate("/static-pages");
            }
          });
      } else {
        createStaticPage(values)
          .unwrap()
          .then((result) => {
            if (result?._id) {
              formik.resetForm();
              navigate("/static-pages");
            }
          });
      }
    },
  });

  return (
    <Box m="20px">
      <Box sx={styles.mainHeadings}>
        <Header
          title={`${pageSlug ? "EDIT" : "CREATE"} STATIC PAGE`}
          subtitle={`${
            pageSlug ? "Edit Static Page" : "Create a New Static Page"
          }`}
        />
        {pageSlug && (
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              onClick={() => navigate("/static-pages")}
              color="secondary"
              variant="contained"
              sx={styles.buttonMd}
            >
              Back to Pages
            </Button>
          </Box>
        )}
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            ...styles.formContainer,
          }}
          className="extra-space"
        >
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
              },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Title"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.title}
              name="title"
              error={!!formik.touched.title && !!formik.errors.title}
              helperText={formik.touched.title && formik.errors.title}
              sx={{ gridColumn: "span 4" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Slug"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.slug}
              name="slug"
              error={!!formik.touched.slug && !!formik.errors.slug}
              helperText={formik.touched.slug && formik.errors.slug}
              sx={{ gridColumn: "span 4" }}
              className="input"
              InputLabelProps={{ shrink: true }}
              disabled={pageSlug}
            />
            <Box
              sx={{
                gridColumn: "span 4",
                "& .ql-container": { height: "300px" },
              }}
            >
              <ReactQuill
                theme="snow"
                value={formik.values.description}
                onChange={(value) => formik.setFieldValue("description", value)}
                placeholder="Write your content..."
              />
              {formik.touched.description && formik.errors.description && (
                <Box sx={{ color: "red", mt: 1 }}>
                  {formik.errors.description}
                </Box>
              )}
            </Box>
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={styles.buttonMd}
            >
              {pageSlug ? "Update" : "Create"}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Add;
