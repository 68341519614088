import { Warning as WarningIcon } from "@mui/icons-material";
import { Box, Button, Container, Paper, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dataGridStyles from "../../styles/dataGridStyles";

const Unauthorized = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);

  const handleGoToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          textAlign: "center",
          borderRadius: 2,
        }}
      >
        <WarningIcon color="error" sx={{ fontSize: 80, mb: 2 }} />
        <Typography variant="h4" component="h1" gutterBottom>
          Unauthorized Access
        </Typography>
        <Typography variant="body1" paragraph>
          You do not have the necessary permissions to view this page.
        </Typography>
        <Box mt={2}>
          <Button
            sx={{
              ...styles.buttonMd,
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
              },
            }}
            variant="contained"
            color="primary"
            onClick={handleGoToDashboard}
          >
            Go to Dashboard
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Unauthorized;
