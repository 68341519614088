import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
  createFilterOptions,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import FileUploadProgress from "components/FileUploadProgress";
import Header from "components/Header";
import { generalSettingInitialValues } from "constants/schemas";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  useGetSettingQuery,
  useUpdateSettingsMutation,
} from "services/utils.service";
import { handleFileUpload, preFillValues } from "utils/common";
import dataGridStyles from "../../styles/dataGridStyles";

const GeneralSetting = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const filter = createFilterOptions();

  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  const [uploadProgress, setUploadProgress] = useState({
    homeBanners: 0,
  });
  const [socialLinks, setSocialLinks] = useState({
    instagram: "",
    facebook: "",
    meesho: "",
  });
  const [bannerData, setBannerData] = useState({
    redirectURL: "",
    image: "",
  });
  const [adBannerData, setAdBannerData] = useState({
    redirectURL: "",
    image: "",
  });
  const [updateSetting] = useUpdateSettingsMutation();
  const { data: settingData = {} } = useGetSettingQuery();
  const fileRef = useRef(null);

  const formik = useFormik({
    initialValues: preFillValues(generalSettingInitialValues, settingData),
    enableReinitialize: true,
    onSubmit: async (values) => {
      values.socialLinks = socialLinks;
      try {
        const result = await updateSetting(values);
        if (result?.data?._id) {
          toast.success("Settings updated successfully!");
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleFileChange = (evt, type) => {
    const file = evt.target.files[0];
    if (file) {
      handleFileUpload(
        file,
        "general",
        (progress) => {
          setUploadProgress((prevUploadProgress) => ({
            ...prevUploadProgress,
            [type]: progress,
          }));
        },
        (url) => {
          setBannerData({
            ...bannerData,
            image: url,
          });
        }
      );
    }
  };

  const handleRemoveImage = (idx) => {
    const newImages = [...formik.values.homePageBanner];
    newImages.splice(idx, 1);
    formik.setFieldValue("homePageBanner", newImages);
  };

  const handleAdFileChange = (evt, type) => {
    const file = evt.target.files[0];
    if (file) {
      handleFileUpload(
        file,
        "general",
        (progress) => {
          setUploadProgress((prevUploadProgress) => ({
            ...prevUploadProgress,
            [type]: progress,
          }));
        },
        (url) => {
          setAdBannerData({
            ...adBannerData,
            image: url,
          });
        }
      );
    }
  };

  const handleRemoveAdImage = (idx) => {
    const newImages = [...formik.values.homePageBanner];
    newImages.splice(idx, 1);
    formik.setFieldValue("advertisementBanner", newImages);
  };

  const handleChangeSocialLink = (evt) => {
    setSocialLinks({
      ...socialLinks,
      [evt.target.name]: evt.target.value,
    });
  };

  const handleChangeMetaTags = (event, newValue) => {
    const updatedNewValue = newValue.map((item) => {
      if (typeof item === "string") {
        return item;
      }
      if (item.inputValue) {
        return item.inputValue;
      }
      return item;
    });

    const uniqueValues = Array.from(new Set(updatedNewValue));
    formik.setFieldValue("commonMetaTags", uniqueValues);
  };

  const onChangeHomeBanner = (evt) => {
    setBannerData({
      ...bannerData,
      redirectURL: evt.target.value,
    });
  };

  const onAddBanner = () => {
    const tempBanner = JSON.parse(JSON.stringify(bannerData));
    formik.setFieldValue(
      "homePageBanner",
      formik.values.homePageBanner?.length <= 0
        ? [tempBanner]
        : [...formik.values.homePageBanner, tempBanner]
    );
    setBannerData({
      redirectURL: "",
      image: "",
    });
    setUploadProgress({});
    if (fileRef.current) {
      fileRef.current.value = "";
    }
    setUploadProgress({ homeBanners: "" });
  };

  const onChangeAdBanner = (evt) => {
    setAdBannerData({
      ...adBannerData,
      redirectURL: evt.target.value,
    });
  };

  const onAddAdBanner = () => {
    const tempBanner = JSON.parse(JSON.stringify(adBannerData));
    formik.setFieldValue(
      "advertisementBanner",
      formik.values.advertisementBanner?.length <= 0
        ? [tempBanner]
        : [...formik.values.advertisementBanner, tempBanner]
    );
    setAdBannerData({
      redirectURL: "",
      image: "",
    });
    setUploadProgress({});
    if (fileRef.current) {
      fileRef.current.value = "";
    }
    setUploadProgress({ adBanners: "" });
  };

  useEffect(() => {
    if (formik.values.socialLinks) {
      setSocialLinks(formik.values.socialLinks);
    }
  }, [formik.values.socialLinks]);

  return (
    <Box m="20px">
      <Box sx={styles.mainHeadings}>
        <Header title="General Settings" />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            ...styles.formContainer,
          }}
        >
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
              },
            }}
          >
            <Typography variant="h4" sx={{ gridColumn: "span 4" }}>
              Home Banners:
            </Typography>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Redirect URL"
              onChange={onChangeHomeBanner}
              name="redirectURL"
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
              value={bannerData?.redirectURL}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gridColumn: "span 2",
              }}
            >
              <TextField
                key={bannerData?.image}
                fullWidth
                variant="filled"
                type="file"
                onChange={(evt) => handleFileChange(evt, "homeBanners")}
                name="image"
                sx={{ gridColumn: "span 2" }}
                className="input"
                InputProps={{
                  accept: "image/*",
                  endAdornment: (
                    <FileUploadProgress
                      progress={uploadProgress?.homeBanners}
                    />
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                label="Banner Image"
                ref={fileRef}
              />
              <small style={{ fontWeight: 700 }}>
                Banner image's resolution should be 1920 x 950
              </small>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                gridColumn: "span 4",
              }}
            >
              <Button
                type="button"
                color="secondary"
                variant="contained"
                sx={{ ...styles.buttonMd }}
                onClick={onAddBanner}
                disabled={!bannerData?.image || !bannerData?.redirectURL}
              >
                Add Home Banner
              </Button>
            </Box>

            <Box
              sx={{
                gridColumn: "span 4",
                display: "flex",
                gap: 2,
                overflow: "auto",
                flexWrap: "wrap",
              }}
            >
              {formik.values.homePageBanner?.length > 0 &&
                formik.values.homePageBanner.map((bannerItem, idx) => {
                  return (
                    <Box
                      key={idx}
                      sx={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        maxWidth: { xs: "100%", sm: "48%", md: "32%" },
                        marginBottom: 2,
                      }}
                    >
                      <img
                        src={bannerItem?.image}
                        alt="banner"
                        style={{
                          width: "100%",
                          maxHeight: 300,
                          height: "auto",
                          display: "block",
                          borderRadius: 4,
                          objectFit: "fill",
                        }}
                      />
                      <Button
                        className="cursor-pointer"
                        onClick={() => handleRemoveImage(idx)}
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          background: "rgba(255, 255, 255, 0.8)",
                          borderRadius: "50%",
                          minWidth: "auto",
                          padding: 0,
                          width: 24,
                          height: 24,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        X
                      </Button>
                      <Typography
                        component="span"
                        sx={{
                          display: "block",
                          textAlign: "center",
                          paddingTop: 1,
                        }}
                      >
                        {bannerItem?.redirectURL}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>

            {/* Advertisement Banner */}
            <Typography variant="h4" sx={{ gridColumn: "span 4" }}>
              Advertisement Banners:
            </Typography>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Redirect URL"
              onChange={onChangeAdBanner}
              name="redirectURL"
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
              value={adBannerData?.redirectURL}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gridColumn: "span 2",
              }}
            >
              <TextField
                key={adBannerData?.image}
                fullWidth
                variant="filled"
                type="file"
                onChange={(evt) => handleAdFileChange(evt, "adBanners")}
                name="image"
                sx={{ gridColumn: "span 2" }}
                className="input"
                InputProps={{
                  accept: "image/*",
                  endAdornment: (
                    <FileUploadProgress
                      progress={uploadProgress?.adBanners}
                    />
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                label="Banner Image"
                ref={fileRef}
              />
              <small style={{ fontWeight: 700 }}>
                Advertisement Banner image's resolution should be 1920 x 950
              </small>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                gridColumn: "span 4",
              }}
            >
              <Button
                type="button"
                color="secondary"
                variant="contained"
                sx={{ ...styles.buttonMd }}
                onClick={onAddAdBanner}
                disabled={!adBannerData?.image || !adBannerData?.redirectURL}
              >
                Add Advertisement Banner
              </Button>
            </Box>

            <Box
              sx={{
                gridColumn: "span 4",
                display: "flex",
                gap: 2,
                overflow: "auto",
                flexWrap: "wrap",
              }}
            >
              {formik.values.advertisementBanner?.length > 0 &&
                formik.values.advertisementBanner.map((adBannerItem, idx) => {
                  return (
                    <Box
                      key={idx}
                      sx={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        maxWidth: { xs: "100%", sm: "48%", md: "32%" },
                        marginBottom: 2,
                      }}
                    >
                      <img
                        src={adBannerItem?.image}
                        alt="banner"
                        style={{
                          width: "100%",
                          maxHeight: 300,
                          height: "auto",
                          display: "block",
                          borderRadius: 4,
                          objectFit: "fill",
                        }}
                      />
                      <Button
                        className="cursor-pointer"
                        onClick={() => handleRemoveAdImage(idx)}
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          background: "rgba(255, 255, 255, 0.8)",
                          borderRadius: "50%",
                          minWidth: "auto",
                          padding: 0,
                          width: 24,
                          height: 24,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        X
                      </Button>
                      <Typography
                        component="span"
                        sx={{
                          display: "block",
                          textAlign: "center",
                          paddingTop: 1,
                        }}
                      >
                        {adBannerItem?.redirectURL}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Contact"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.contact}
              name="contact"
              error={!!formik.touched.contact && !!formik.errors.contact}
              helperText={formik.touched.contact && formik.errors.contact}
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              name="email"
              error={!!formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Address"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.address}
              name="address"
              error={!!formik.touched.address && !!formik.errors.address}
              helperText={formik.touched.address && formik.errors.address}
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Google Api Key"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.googleApiKey}
              name="googleApiKey"
              error={
                !!formik.touched.googleApiKey && !!formik.errors.googleApiKey
              }
              helperText={
                formik.touched.googleApiKey && formik.errors.googleApiKey
              }
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Google Latitude"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.googleLat}
              name="googleLat"
              error={!!formik.touched.googleLat && !!formik.errors.googleLat}
              helperText={formik.touched.googleLat && formik.errors.googleLat}
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Google Longitude"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.googleLong}
              name="googleLong"
              error={!!formik.touched.googleLong && !!formik.errors.googleLong}
              helperText={formik.touched.googleLong && formik.errors.googleLong}
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <Typography variant="h4" sx={{ gridColumn: "span 4" }}>
              Social Links:
            </Typography>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Instagram"
              onChange={handleChangeSocialLink}
              value={socialLinks?.instagram}
              name="instagram"
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Facebook"
              onChange={handleChangeSocialLink}
              value={socialLinks?.facebook}
              name="facebook"
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Meesho"
              onChange={handleChangeSocialLink}
              value={socialLinks?.meesho}
              name="meesho"
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <Typography variant="h4" sx={{ gridColumn: "span 4" }}>
              SEO Settings:
            </Typography>
            <Autocomplete
              fullWidth
              multiple
              value={formik.values.commonMetaTags}
              onChange={handleChangeMetaTags}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== "") {
                  filtered.push(params.inputValue);
                }

                return filtered;
              }}
              options={formik.values.commonMetaTags}
              getOptionLabel={(option) => option}
              renderOption={(props, option) => <li {...props}>{option}</li>}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Common Meta Tags"
                  placeholder="Create options"
                />
              )}
              className="select-lg"
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Meta Title"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.metaTitle}
              name="metaTitle"
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Meta Description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.metaDescription}
              name="metaDescription"
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <Typography variant="h4" sx={{ gridColumn: "span 4" }}>
              Shipping Config:
            </Typography>
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="COD Shipping"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.codShipping}
              name="codShipping"
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Package Weight (Grams)"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.packageWeight}
              name="packageWeight"
              sx={{ gridColumn: "span 2" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={styles.buttonMd}
            >
              Update
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default GeneralSetting;
