import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  colorInitialValues,
  colorValidationSchema,
} from "constants/schemas/masters";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useAddColorMutation, useEditColorMutation } from "services";
import { setShowMasterModal } from "store/slices/utilSlice";
import { useTheme } from "@mui/material";
import dataGridStyles from "../../../styles/dataGridStyles";
import { preFillValues } from "utils/common";

const ColorContent = () => {
  const dispatch = useDispatch();
  // mutations
  const [colorMutation] = useAddColorMutation();
  const [editColorMutation] = useEditColorMutation();
  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);

  // store
  const selectedModelContent = useSelector(
    (state) => state.utils.selectedModelContent
  );

  const formik = useFormik({
    initialValues: preFillValues(colorInitialValues, selectedModelContent),
    validationSchema: colorValidationSchema,
    onSubmit: (values) => {
      if (selectedModelContent?._id) {
        editColorMutation({
          payload: values,
          id: selectedModelContent._id,
        });
      } else {
        colorMutation(values);
      }
      dispatch(setShowMasterModal(false));
    },
  });
  return (
    <Box sx={{ ...styles.formContainer, ...styles.modalContainer }}>
      <Typography variant="h6" gutterBottom>
        {selectedModelContent?._id ? "Edit Color" : "Add New Color"}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="Color Name"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          name="name"
          error={formik.touched.name && Boolean(formik.errors.name)}
          // className="input"
        />
        <TextField
          type="color"
          label="Color"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.hexCode}
          name="hexCode"
          error={formik.touched.hexCode && Boolean(formik.errors.hexCode)}
          className="input input-1"
        />
        <TextField
          type="number"
          label="Sort Order"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.sortOrder}
          name="sortOrder"
          error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)}
          className="input fieldset-1"
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default ColorContent;
