import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const RoleGuard = ({ allowedRoles, children }) => {
  const userInfo = useSelector((state) => state.auth.user);

  if (!allowedRoles.includes(userInfo?.roles)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default RoleGuard;