import React, { useState, useRef } from 'react';
import { Box, IconButton } from "@mui/material";
import { PlayArrow, Pause } from "@mui/icons-material";

const VideoPlayer = ({ src, width = '100px', height = '100px' }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Box sx={{ position: 'relative', width, height }}>
      <video
        ref={videoRef}
        src={src}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      <IconButton
        onClick={handlePlayPause}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
        }}
      >
        {isPlaying ? (
          <Pause sx={{ color: 'white' }} />
        ) : (
          <PlayArrow sx={{ color: 'white' }} />
        )}
      </IconButton>
    </Box>
  );
};

export default VideoPlayer;