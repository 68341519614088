import { API_ENDPOINTS } from "../constants/endpoints";
import { apiSlice, handleResponse } from "./base.service";

export const shippingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createShipment: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.CREATE_SHIPMENT,
        method: "POST",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    generateAwb: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.GENERATE_AWB,
        method: "POST",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    generateLabel: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.GENERATE_LABEL,
        method: "POST",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    generateManifest: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.GENERATE_MANIFEST,
        method: "POST",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    generatePickup: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.GENERATE_PICKUP,
        method: "POST",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    createQuickOrder: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.CREATE_QUICK_ORDER,
        method: "POST",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
  }),
});

export const {
  useCreateShipmentMutation,
  useGenerateAwbMutation,
  useGenerateLabelMutation,
  useGenerateManifestMutation,
  useGeneratePickupMutation,
  useCreateQuickOrderMutation,
} = shippingApiSlice;
