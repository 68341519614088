import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

const ProtectedLayout = () => {
  const token = useSelector((state) => state.auth.token);
  const [isSidebar, setIsSidebar] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <Box className={`app ${isCollapsed ? "sidemenu-collapsed" : ""}`} >
      <Sidebar isSidebar={isSidebar} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Outlet />
      </main>
    </Box>
  );
};

export default ProtectedLayout;
