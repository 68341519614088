import {
  BrandsContent,
  CaptionTagContent,
  ClothLengthContent,
  ColorContent,
  DiscountContent,
  FabricContent,
  OccasionsContent,
  PatternsContent,
  PaymentModeContent,
  SectionContent,
  SizeTypeContent,
} from "components/modals/masterModalContent";

export const MASTER_MODAL_CONTENT = {
  brands: BrandsContent,
  captionTags: CaptionTagContent,
  clothLengths: ClothLengthContent,
  colors: ColorContent,
  discounts: DiscountContent,
  fabrics: FabricContent,
  occasions: OccasionsContent,
  patterns: PatternsContent,
  paymentModes: PaymentModeContent,
  sections: SectionContent,
  sizeTypes: SizeTypeContent,
};

export const GLOBAL_SETTINGS = {
  PRODUCT_QTY_MIN: 1,
  PRODUCT_QTY_MAX: 10,
  CURRENCY: "&#8377;",
};