import { IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import { MASTER_MODAL_CONTENT } from "constants/constantData";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setShowMasterModal } from "store/slices/utilSlice";

const MasterAddModal = () => {
  // hooks
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  // store
  const showMasterModal = useSelector((state) => state.utils.showMasterModal);

  const ModalContent = type ? MASTER_MODAL_CONTENT[type] : <></>;

  const handleClose = () => {
    dispatch(setShowMasterModal(false));
  };

  return (
    <div>
      <Modal open={showMasterModal} onClose={handleClose} >
        <ModalContent />
      </Modal>
    </div>
  );
};

export default MasterAddModal;
