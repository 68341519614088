import * as Yup from "yup";

// INITIAL VALUES

export const brandInitialValues = {
  name: "",
  image: "",
  sortOrder: 0,
};

export const captionTagInitialValues = {
  name: "",
  sortOrder: 0,
};

export const clothLengthInitialValues = {
  name: "",
  sortOrder: 0,
};

export const colorInitialValues = {
  name: "",
  hexCode: "",
  sortOrder: 0,
};

export const discountInitialValues = {
  name: "",
  value: 0,
  sortOrder: 0,
};

export const fabricInitialValues = {
  name: "",
  image: "",
  sortOrder: 0,
};

export const occasionInitialValues = {
  name: "",
  image: "",
  sortOrder: 0,
};

export const patternInitialValues = {
  name: "",
  image: "",
  sortOrder: 0,
};

export const paymentModeInitialValues = {
  name: "",
  sortOrder: 0,
};

export const sectionInitialValues = {
  name: "",
  tag: '',
  sortOrder: 0,
};

export const sizeTypeInitialValues = {
  name: "",
  values: [],
};

// VALIDATION SCHEMA

export const brandValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // image: Yup.string().required("Image is required"),
  sortOrder: Yup.number().min(1).required("Sort Order is required"),
});

export const captionTagValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  sortOrder: Yup.number().min(1).required("Sort Order is required"),
});

export const clothLengthValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  sortOrder: Yup.number().min(1).required("Sort Order is required"),
});

export const colorValidationSchema = Yup.object().shape({
  name: Yup.string().required("Color Name is required"),
  hexCode: Yup.string().required("Hex Code is required"),
  sortOrder: Yup.number().min(1).required("Sort Order is required"),
});

export const discountValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  value: Yup.number().min(1).required("Value is required"),
  sortOrder: Yup.number().min(1).required("Sort Order is required"),
});

export const fabricValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // image: Yup.string().required("Image is required"),
  sortOrder: Yup.number().min(1).required("Sort Order is required"),
});

export const occasionValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // image: Yup.string().required("Image is required"),
  sortOrder: Yup.number().min(1).required("Sort Order is required"),
});

export const patternValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // image: Yup.string().required("Image is required"),
  sortOrder: Yup.number().min(1).required("Sort Order is required"),
});

export const paymentModeValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  sortOrder: Yup.number().min(1).required("Sort Order is required"),
});

export const sectionValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  tag: Yup.string().required("Tag is required"),
  sortOrder: Yup.number().min(1).required("Sort Order is required"),
});

export const sizeTypeValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  values: Yup.array().min(1).required("Value is required"),
});
