import { CssBaseline, ThemeProvider } from "@mui/material";
import MasterAddModal from "components/modals/MasterAddModal";
import SiteRoutes from "./routes";
import { AuthProvider } from "./context/Auth.Context";
import { ColorModeContext, useMode } from "./theme";
import { ToastContainer } from "react-toastify";
import ConfirmModal from "components/modals/ConfirmModal";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <SiteRoutes />
          <MasterAddModal />
          <ConfirmModal />
          <ToastContainer position="top-right" hideProgressBar />
        </AuthProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
