import { API_ENDPOINTS } from "../constants/endpoints";
import { apiSlice, handleResponse } from "./base.service";

export const masterApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSizeTypes: builder.query({
      query: () => API_ENDPOINTS.GET_SIZE_TYPES,
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      providesTags: ['SizeTypes'],
    }),
    getCaptionTag: builder.query({
      query: () => API_ENDPOINTS.GET_CAPTION_TAG,
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      providesTags: ['CaptionTags'],
    }),
    getClothLength: builder.query({
      query: () => API_ENDPOINTS.GET_CLOTH_LENGTH,
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      providesTags: ['ClothLengths'],
    }),
    getColors: builder.query({
      query: () => API_ENDPOINTS.GET_COLORS,
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      providesTags: ['Colors'],
    }),
    getDiscount: builder.query({
      query: () => API_ENDPOINTS.GET_DISCOUNT,
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      providesTags: ['Discounts'],
    }),
    getFabric: builder.query({
      query: () => API_ENDPOINTS.GET_FABRIC,
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      providesTags: ['Fabrics'],
    }),
    getOccasions: builder.query({
      query: () => API_ENDPOINTS.GET_OCCASIONS,
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      providesTags: ['Occasions'],
    }),
    getPatterns: builder.query({
      query: () => API_ENDPOINTS.GET_PATTERNS,
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      providesTags: ['Patterns'],
    }),
    getBrands: builder.query({
      query: () => API_ENDPOINTS.GET_BRANDS,
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      providesTags: ['Brands'],
    }),
    getSections: builder.query({
      query: () => API_ENDPOINTS.GET_SECTIONS,
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      providesTags: ['Sections'],
    }),
    getPaymentModes: builder.query({
      query: () => API_ENDPOINTS.GET_PAYMENT_MODES,
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      providesTags: ['PaymentModes'],
    }),
    addSizeTypes: builder.mutation({
      query: (data) => ({
        url: API_ENDPOINTS.ADD_SIZE_TYPE,
        method: "POST",
        body: data,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['SizeTypes'],
    }),
    addCaptionTag: builder.mutation({
      query: (data) => ({
        url: API_ENDPOINTS.ADD_CAPTION_TAG,
        method: "POST",
        body: data,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['CaptionTags'],
    }),
    addClothLength: builder.mutation({
      query: (data) => ({
        url: API_ENDPOINTS.ADD_CLOTH_LENGTH,
        method: "POST",
        body: data,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['ClothLengths'],
    }),
    addColor: builder.mutation({
      query: (data) => ({
        url: API_ENDPOINTS.ADD_COLOR,
        method: "POST",
        body: data,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Colors'],
    }),
    addDiscount: builder.mutation({
      query: (data) => ({
        url: API_ENDPOINTS.ADD_DISCOUNT,
        method: "POST",
        body: data,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Discounts'],
    }),
    addFabric: builder.mutation({
      query: (data) => ({
        url: API_ENDPOINTS.ADD_FABRIC,
        method: "POST",
        body: data,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Fabrics'],
    }),
    addOccasion: builder.mutation({
      query: (data) => ({
        url: API_ENDPOINTS.ADD_OCCASION,
        method: "POST",
        body: data,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Occasions'],
    }),
    addPattern: builder.mutation({
      query: (data) => ({
        url: API_ENDPOINTS.ADD_PATTERN,
        method: "POST",
        body: data,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Patterns'],
    }),
    addSection: builder.mutation({
      query: (data) => ({
        url: API_ENDPOINTS.ADD_SECTION,
        method: "POST",
        body: data,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Sections'],
    }),
    addBrand: builder.mutation({
      query: (data) => ({
        url: API_ENDPOINTS.ADD_BRAND,
        method: "POST",
        body: data,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Brands'],
    }),
    addPaymentMode: builder.mutation({
      query: (data) => ({
        url: API_ENDPOINTS.ADD_PAYMENT_MODE,
        method: "POST",
        body: data,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['PaymentModes'],
    }),
    editSizeTypes: builder.mutation({
      query: (data) => ({
        url: `${API_ENDPOINTS.EDIT_SIZE_TYPE}/${data?.id}`,
        method: "PUT",
        body: data?.payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['SizeTypes'],
    }),
    editCaptionTag: builder.mutation({
      query: (data) => ({
        url: `${API_ENDPOINTS.EDIT_CAPTION_TAG}/${data?.id}`,
        method: "PUT",
        body: data?.payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['CaptionTags'],
    }),
    editClothLength: builder.mutation({
      query: (data) => ({
        url: `${API_ENDPOINTS.EDIT_CLOTH_LENGTH}/${data?.id}`,
        method: "PUT",
        body: data?.payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['ClothLengths'],
    }),
    editColor: builder.mutation({
      query: (data) => ({
        url: `${API_ENDPOINTS.EDIT_COLOR}/${data?.id}`,
        method: "PUT",
        body: data?.payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Colors'],
    }),
    editDiscount: builder.mutation({
      query: (data) => ({
        url: `${API_ENDPOINTS.EDIT_DISCOUNT}/${data?.id}`,
        method: "PUT",
        body: data?.payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Discounts'],
    }),
    editFabric: builder.mutation({
      query: (data) => ({
        url: `${API_ENDPOINTS.EDIT_FABRIC}/${data?.id}`,
        method: "PUT",
        body: data?.payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Fabrics'],
    }),
    editOccasion: builder.mutation({
      query: (data) => ({
        url: `${API_ENDPOINTS.EDIT_OCCASION}/${data?.id}`,
        method: "PUT",
        body: data?.payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Occasions'],
    }),
    editPattern: builder.mutation({
      query: (data) => ({
        url: `${API_ENDPOINTS.EDIT_PATTERN}/${data?.id}`,
        method: "PUT",
        body: data?.payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Patterns'],
    }),
    editSection: builder.mutation({
      query: (data) => ({
        url: `${API_ENDPOINTS.EDIT_SECTION}/${data?.id}`,
        method: "PUT",
        body: data?.payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Sections'],
    }),
    editBrand: builder.mutation({
      query: (data) => ({
        url: `${API_ENDPOINTS.EDIT_BRAND}/${data?.id}`,
        method: "PUT",
        body: data?.payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['Brands'],
    }),
    editPaymentMode: builder.mutation({
      query: (data) => ({
        url: `${API_ENDPOINTS.EDIT_PAYMENT_MODE}/${data?.id}`,
        method: "PUT",
        body: data?.payload,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['PaymentModes'],
    }),
  }),
});

export const {
  useGetSizeTypesQuery,
  useGetCaptionTagQuery,
  useGetClothLengthQuery,
  useGetColorsQuery,
  useGetDiscountQuery,
  useGetFabricQuery,
  useGetOccasionsQuery,
  useGetPatternsQuery,
  useGetBrandsQuery,
  useGetSectionsQuery,
  useGetPaymentModesQuery,
  useAddSizeTypesMutation,
  useAddCaptionTagMutation,
  useAddClothLengthMutation,
  useAddColorMutation,
  useAddDiscountMutation,
  useAddFabricMutation,
  useAddOccasionMutation,
  useAddPatternMutation,
  useAddSectionMutation,
  useAddBrandMutation,
  useAddPaymentModeMutation,
  useEditSizeTypesMutation,
  useEditCaptionTagMutation,
  useEditClothLengthMutation,
  useEditColorMutation,
  useEditDiscountMutation,
  useEditFabricMutation,
  useEditOccasionMutation,
  useEditPatternMutation,
  useEditSectionMutation,
  useEditBrandMutation,
  useEditPaymentModeMutation,
} = masterApiSlice;
