import { API_ENDPOINTS } from "../constants/endpoints";
import { apiSlice, handleResponse } from "./base.service";

export const staticPageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createStaticPage: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.CREATE_STATIC_PAGE,
        method: "POST",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    updateStaticPage: builder.mutation({
      query: ({body, slug}) => ({
        url: `${API_ENDPOINTS.CREATE_STATIC_PAGE}/${slug}`,
        method: "PUT",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    getStaticPage: builder.query({
      query: (slug) => ({
        url: `${API_ENDPOINTS.GET_STATIC_PAGE}/${slug}`,
        method: "GET",
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    getStaticPageList: builder.query({
      query: ({params}) => ({
        url: API_ENDPOINTS.GET_STATIC_PAGE,
        method: "GET",
        params
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
  }),
});

export const { useCreateStaticPageMutation, useGetStaticPageQuery, useUpdateStaticPageMutation, useGetStaticPageListQuery } = staticPageApiSlice;
