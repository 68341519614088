import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, TextField, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useDebounce, useDeleteRecord } from "hooks";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useGetColorsQuery } from "services";
import {
  setConfirmModalConfig,
  setSelectedModelContent,
  setShowMasterModal,
} from "store/slices/utilSlice";
import { tokens } from "theme";
import dataGridStyles from "../../../styles/dataGridStyles";
import { useSearchParams } from "react-router-dom";
import StyledDataGrid from "components/datagrids/StyledDataGrid";

const List = () => {
  // hooks
  const dispatch = useDispatch();
  const { deleteRecord } = useDeleteRecord();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchName = searchParams.get("name");
  const debouncedNameQry = useDebounce(searchName, 500);

  // queries
  const {
    data: colorsData = [],
    isLoading,
    refetch,
  } = useGetColorsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  // states
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
renderCell: (params) => (
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "sortOrder",
      headerName: "Sorted Order",
      flex: 1,
    },
    {
      field: "hexCode",
      headerName: "Color",
      flex: 1,
      renderCell: (params) => {
        const hexCode = params.row.hexCode;
        return (
          <>
            <Box
              sx={{
                backgroundColor: hexCode,
                fontSize: "12px",
                fontWeight: "bold",
                padding: "15px",
                borderRadius: "5px",
                margin: "0 5px",
              }}
            />
            {hexCode}
          </>
        );
      },
    },
    {
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" gap={2}>
          <span
            className="cursor-pointer"
            onClick={() => handleEdit(params.row)}
          >
            <EditIcon />
          </span>
          <span
            className="cursor-pointer"
            onClick={() => {
              const payload = {
                modelName: "Color",
                status: true,
                modelId: params.row._id,
              };
              dispatch(
                setConfirmModalConfig({
                  visible: true,
                  data: {
                    onSubmit: () => deleteRecord(payload, refetch),
                    content: {
                      heading: "Delete Color?",
                      description: "Are you sure you want to delete this color?",
                    },
                  },
                })
              );
            }}
          >
            <DeleteIcon />
          </span>
        </Box>
      ),
    },
  ];
  const handleChange = (evt) => {
    setSearchParams({
      name: evt.target.value,
    });
  };
  const handleOpen = () => {
    dispatch(setShowMasterModal(true));
    dispatch(setSelectedModelContent({}));
  };

  const handleEdit = (row) => {
    dispatch(setShowMasterModal(true));
    dispatch(setSelectedModelContent(row));
  };

  return (
    <>
      <Box m="20px">
        <Box
          sx={{
            ...styles.headingsContainer,
            ...styles.dFlex,
            ...styles.alignItemsStart,
            ...styles.justifyContentBetween,
          }}
        >
          <Box sx={{ ...styles.mainHeadings }}>
            <Header title="COLORS" subtitle="Managing the Colors" />
          </Box>
          <Box>
            <Button sx={styles.buttonMd} onClick={handleOpen}>
              <AddOutlinedIcon sx={{ mr: "10px" }} />
              Add Color
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            ...styles.formContainer,
          }}
        >
          {/* <Box
            sx={{
              ...styles.searchBar,
            }}
          >
            <TextField
              placeholder="Search"
              onChange={handleChange}
              value={searchName}
            />
          </Box> */}
          <StyledDataGrid
            rows={colorsData}
            columns={columns}
            getRowId={(row) => row._id}
            loading={isLoading}
          />
        </Box>
      </Box>
    </>
  );
};

export default List;
