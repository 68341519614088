import EyeIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useDebounce } from "hooks";
import { useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  useCreateQuickOrderMutation,
  useCreateShipmentMutation,
  useGenerateAwbMutation,
  useGenerateLabelMutation,
  useGenerateManifestMutation,
  useGeneratePickupMutation,
  useGetOrdersQuery,
  useUpdateStatusMutation,
} from "services";
import { tokens } from "theme";
import { formatTimestamp, getAllSearchParams } from "utils/common";
import dataGridStyles from "../../styles/dataGridStyles";
import StyledDataGrid from "components/datagrids/StyledDataGrid";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { toast } from "react-toastify";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import { setConfirmModalConfig } from "store/slices/utilSlice";

const List = () => {
  const [page, setPage] = useState(0); // Note: DataGrid uses 0-based indexing for pages
  const [pageSize, setPageSize] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchName = searchParams.get("search");
  const searchStatus = searchParams.get("status");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const debouncedNameQry = useDebounce(searchName, 500);
  const [searchState, setSearchState] = useState("");
  const dispatch = useDispatch();

  // queries
  const {
    data: ordersData = [],
    isLoading,
    refetch,
  } = useGetOrdersQuery(
    {
      params: {
        page: page + 1,
        limit: pageSize,
        search: debouncedNameQry ?? undefined,
        status: searchStatus ?? undefined,
        startDate: startDate ?? undefined,
        endDate: endDate ?? undefined,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [updateStatus] = useUpdateStatusMutation();
  // shipping apis
  const [createQuickOrder] = useCreateQuickOrderMutation();

  const handleCancelOrder = async (orderId) => {
    try {
      const result = await updateStatus({
        orderId,
        status: "2",
      });
      if (result?.data?._id) {
        refetch();
        toast.success("Order cancelled successfully!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAcceptOrder = async (orderId) => {
    try {
      // create shipment
      const shipmentResult = await createQuickOrder({ order_id: orderId });
      if (shipmentResult?.data?.payload?.awb_assign_error) {
        toast.info(shipmentResult?.data?.payload?.awb_assign_error);
      } else if (shipmentResult?.data?.payload?.shipment_id) {
        toast.success("Order accepted successfully!");
        refetch();
      } else {
        toast.error("Something went wrong!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "orderId",
      headerName: "Order ID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => (
        <img
          src={
            params.row?.products[0]?.mainImage ??
            params.row?.products[0]?.productId?.mainImage
          }
          alt={params.row?.product?.name}
          style={{ width: "50px", height: "50px", objectFit: "contain" }}
        />
      ),
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      renderCell: (params) => <span>&#8377; {params.value}</span>,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => <span>{formatTimestamp(params.value)}</span>,
    },
    {
      field: "statusText",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Chip label={params.value} size="small" variant="outlined" />
        </Tooltip>
      ),
    },
    {
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" gap={2}>
        {/* Render placeholders if Accept or Cancel icons are not visible */}
        {params.row.status === "68" ? (
          <>
            <Tooltip title="Accept Order">
              <span
                className="cursor-pointer"
                onClick={() => {
                  dispatch(
                    setConfirmModalConfig({
                      visible: true,
                      data: {
                        onSubmit: () => handleAcceptOrder(params.row._id),
                        content: {
                          heading: "Accept Order",
                          description: "Are you sure you want to accept this order?",
                        },
                      },
                    })
                  );
                }}
              >
                <CheckCircleIcon />
              </span>
            </Tooltip>
            <Tooltip title="Cancel Order">
              <span
                className="cursor-pointer"
                onClick={() => {
                  dispatch(
                    setConfirmModalConfig({
                      visible: true,
                      data: {
                        onSubmit: () => handleCancelOrder(params.row._id),
                        content: {
                          heading: "Cancel Order",
                          description: "Are you sure you want to cancel this order?",
                        },
                      },
                    })
                  );
                }}
              >
                <CancelIcon />
              </span>
            </Tooltip>
          </>
        ) : (
          // Placeholder to maintain layout if Accept/Cancel icons are not shown
          <>
            <Box width={21}></Box>
            <Box width={21}></Box>
          </>
        )}
      
        {/* The View icon always stays in the 3rd position */}
        <Link to={`/orders/${params.row._id}`}>
          <EyeIcon />
        </Link>
      </Box>
      
      ),
    },
  ];

  const handleChange = (key, evt) => {
    setSearchParams({
      ...getAllSearchParams(searchParams),
      [key]: evt.target.value,
    });
    if (key === "search") {
      setSearchState(evt.target.value);
    }
  };

  const handleClear = () => {
    setSearchParams({});
    setSearchState("");
  };

  const handleDateChange = (key, value) => {
    console.log(key, value);
    setSearchParams({
      ...getAllSearchParams(searchParams),
      [key]: value,
    });
  };

  useEffect(() => {
    if (searchName) {
      setSearchState(searchName);
    }
  }, [searchName]);

  return (
    <Box m="20px">
      <Box
        sx={{
          ...styles.headingsContainer,
          ...styles.dFlex,
          ...styles.alignItemsStart,
          ...styles.justifyContentBetween,
        }}
      >
        <Box sx={styles.mainHeadings}>
          <Header title="ORDERS" subtitle="Managing the Orders" />
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.formContainer,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                placeholder="Search by order Id or tracking Id"
                onChange={(evt) => handleChange("search", evt)}
                value={searchState}
                variant="outlined" // Use outlined variant for consistent styling
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-helper-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={searchStatus ?? ""}
                  label="Status"
                  onChange={(evt) => handleChange("status", evt)}
                  placeholder="Filter by status"
                >
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Processing">Processing</MenuItem>
                  <MenuItem value="Shipped">Shipped</MenuItem>
                  <MenuItem value="Delivered">Delivered</MenuItem>
                  <MenuItem value="Cancelled">Cancelled</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  inputFormat="YYYY-MM-DD" // Specify the input format
                  onChange={(date) =>
                    handleDateChange(
                      "startDate",
                      dayjs(date).format("YYYY-MM-DD")
                    )
                  }
                  value={startDate ? dayjs(startDate) : null}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth variant="outlined" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  inputFormat="YYYY-MM-DD"
                  onChange={(date) =>
                    handleDateChange(
                      "endDate",
                      dayjs(date).format("YYYY-MM-DD")
                    )
                  }
                  value={endDate ? dayjs(endDate) : null}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth variant="outlined" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            {(searchName || searchStatus || startDate || endDate) && (
              <Grid item xs={12}>
                <Button
                  type="button"
                  onClick={handleClear}
                  className="btn-clear"
                  style={styles.buttonMd}
                >
                  Clear
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>

        {/* <Box
          m="0px -14px 0"
          height="75vh"
          sx={{
            ...styles.listContainer,
          }}
        > */}
        <StyledDataGrid
          rows={ordersData?.items || []}
          columns={columns}
          getRowId={(row) => row?._id}
          loading={isLoading}
          pagination
          page={page}
          pageSize={pageSize}
          rowCount={ordersData?.count}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          className="no-shaddow"
          noResultText="No orders found"
        />
        {/* </Box> */}
      </Box>
    </Box>
  );
};

export default List;
