// src/styles/dataGridStyles.js
import { tokens } from "theme";

const dataGridStyles = (mode) => {
  const colors = tokens(mode);
  return {
    viewForm: {
      "& .d-flex": {
        display: "flex",
        alignItems: "center",
      },
      "& .control-label": {
        textAlign: "right",
        fontSize: "13px",
        fontWeight: 700,
        //width: "100%",
        display: "block",
        paddingRight: "15px",
        minWidth: "200px",
      },
      "& .form-control": {
        border: "solid 1px #e0e0e0",
        height: "34px",
        padding: "6px 12px",
        width: "100%",
        flex: "1 1 auto",
      },
      "& textarea": {
        minHeight: "80px",
      },
      "& .mb-15": {
        marginBottom: "15px",
      },
    },
    headingsContainer: {
      //border:"solid 1px red",
      "&  div": {
        marginBottom: "0px",
        //border:"solid 1px blue",
      },
      marginBottom: "30px",
    },
    dFlex: {
      display: "flex",
    },
    alignItemsStart: {
      alignItems: "flex-start",
    },
    alignItemsCenter: {
      alignItems: "center",
    },
    justifyContentBetween: {
      justifyContent: "space-between",
    },
    mainHeadings: {
      //border: "solid 1px blue;",
      marginBottom: "30px",
      "& .MuiTypography-h2": {
        color: colors.blackAccent[700],
      },
      "& .MuiTypography-h5": {
        color: colors.blueAccent[600],
      },
    },
    buttonMd: {
      backgroundColor: colors.blueAccent[600],
      color: colors.whiteAccent[100],
      fontSize: "14px",
      fontWeight: "bold",
      padding: "10px 20px",
      "&:focus, &:hover": {
        outline: "none",
        boxShadow: "none",
        backgroundColor: colors.blueAccent[600],
      },
    },
    buttonxs: {
      backgroundColor: colors.blueAccent[600],
      color: colors.whiteAccent[100],
      fontSize: "10px",
      fontWeight: "bold",
      padding: "5px 10px",
      "&:focus, &:hover": {
        outline: "none",
        boxShadow: "none",
        backgroundColor: colors.blueAccent[600],
      },
    },
    formContainer: {
      backgroundColor: colors.whiteAccent[100],
      boxShadow:
        "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(15, 22, 36, 0.06) 0px 1px 2px 0px, rgba(15, 22, 36, 0.1) 0px 1px 3px 0px",
      borderRadius: "4px",
      padding: "14px 14px",
      "&.extra-space": {
        paddingTop: "30px",
      },
      "& .MuiFilledInput-root": {
        paddingRight: "0",
      },
      "& .MuiFormControl-root.input": {
        borderRadius: "0",
        "& .MuiFormLabel-root": {
          transform: "translate(12px, 12px) scale(1)",
          "&.Mui-focused, &.MuiInputLabel-shrink": {
            transform: "translate(12px, 5px) scale(0.75)",
          },
          "&.MuiInputLabel-shrink": {
            //border: "solid 1px red",
          },
        },
        "& > .MuiFilledInput-underline": {
          "& > .MuiInputBase-input": {
            backgroundColor: colors.whiteAccent[100],
            border: `solid 1px ${colors.whiteAccent[300]}`,
            paddingTop: "19px",
            paddingBottom: "6px",
          },
          "& input[type='file']": {
            //paddingTop: "13px",
            //paddingBottom: "12px",
          },
        },
      },
      "& .autocomplete-1": {
        "& .MuiFilledInput-root": {
          paddingTop: "11px",
          background: "transparent",
          border: "solid 1px #e0e0e0",
          borderTopLeftRadius: "0",
          borderTopRightRadius: "0",
        },
        "& .MuiInputLabel-root": {
          top: "-4px",
        },
        "& .MuiAutocomplete-tag": {
          maxHeight: "24px",
          margin: "4px 0 0",
          "& .MuiSvgIcon-root": {
            fontSize: "18px",
          },
        },
      },
      "& .autocomplete": {
        "& .MuiFilledInput-root": {
          paddingTop: "13px",
        },
        "& .MuiInputLabel-root": {
          top: "-2px",
        },
      },
      "& .MuiFormControl-root.input.fieldset-1": {
        "& > .MuiFormLabel-root": {
          transform: "translate(14px, -9px) scale(0.75)",
        },
      },
      "& .MuiFormControl-root.input.input-1": {
        "& .MuiFormLabel-root": {
          transform: "translate(14px, -9px) scale(0.75)",
          backgroundColor: colors.whiteAccent[100],
          paddingLeft: "5px",
          paddingRight: "5px",
        },
      },
      "& .MuiAutocomplete-root.select": {
        "&-lg": {
          //border: "solid 1px red",
          "& .MuiOutlinedInput-root": {
            //border: "solid 1px red",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: `${colors.whiteAccent[300]}`,
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
        },
        "& > .MuiFormControl-fullWidth": {
          "& .MuiFormLabel-root": {
            transform: "translate(12px, 12px) scale(1)",
            "&.Mui-focused": {
              transform: "translate(12px, 5px) scale(0.75)",
            },
          },
          "& .MuiFilledInput-root": {
            paddingTop: "0",
            backgroundColor: colors.whiteAccent[100],
            border: `solid 1px ${colors.whiteAccent[300]}`,
            borderRadius: "0",
          },
          "&  .MuiInputBase-input": {
            paddingTop: "19px",
            paddingBottom: "6px",
          },
        },
      },

      "& .MuiFilledInput-underline": {
        "&:before": {
          border: "none",
        },
        "&:hover:not(.Mui-disabled, .Mui-error)": {
          "&:before": {
            border: "none",
          },
        },
      },
    },
    modalContainer: {
      //border: "solid 1px blue",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      //border: "2px solid blue",
      boxShadow:
        "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(15, 22, 36, 0.06) 0px 1px 2px 0px, rgba(15, 22, 36, 0.1) 0px 1px 3px 0px",
      borderRadius: "4px",
      boxShadow: 24,
      padding: "32px 32px !important",

      "& h6": {
        //border: "solid 1px red",
        fontSize: "18px",
        marginBottom: "12px",
      },
      "& .MuiButton-containedPrimary": {
        backgroundColor: colors.blueAccent[600],
        "&:hover": {
          backgroundColor: colors.blueAccent[600],
        },
      },
      "& .MuiButton-containedSecondary": {
        backgroundColor: colors.grey[100],
        color: colors.whiteAccent[100],
        "&:hover": {
          backgroundColor: colors.grey[100],
          color: colors.whiteAccent[100],
        },
      },
    },

    mediumButton: {
      backgroundColor: colors.blueAccent[600],
      color: colors.whiteAccent[100],
      fontSize: "14px",
      fontWeight: "bold",
      padding: "10px 20px",
      "&:focus, &:hover": {
        outline: "none",
        boxShadow: "none",
        backgroundColor: colors.blueAccent[600],
      },
    },
    searchBar: {
      display: "flex",
      justifyContent: "flex-end",
      columnGap: "16px",
      "& .selectbox-1": {
        maxHeight: "40px",
        maxWidth: "250px",
        "& .MuiInputBase-input": {
          minWidth: "inherit !important",
        },
        "& .MuiInputLabel-root": {
          top: "50%",
          transform: "translateY(-50%)",
          paddingLeft: "8px",
        },
      },
      "& .autocomplete-1": {
        "& .MuiInputBase-input": {
          paddingTop: "9px !important",
        },
        "& .MuiFormLabel-root": {
          top: "-5px",
        },
      },
      "& .MuiInputBase-root": {
        "&.Mui-focused": {
          "& .MuiInputBase-input": {
            boxShadow: "none",
          },
        },
        marginBottom: "16px",
        "& .MuiInputBase-input": {
          minWidth: "320px",
          height: "40px",
          boxSizing: "border-box",
          paddingTop: "4px",
          paddingBottom: "4px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px !important",
        },
      },
      "& .btn-clear": {
        background: "#64748b",
        color: "#ffffff",
        height: "40px",
      },
    },

    // listContainer: {
    //   //border: "solid 1px red",
    //   "& .MuiDataGrid-root": {
    //     border: "none",
    //     boxShadow:
    //       "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(15, 22, 36, 0.06) 0px 1px 2px 0px, rgba(15, 22, 36, 0.1) 0px 1px 3px 0px",
    //     //border: "solid 1px red",
    //   },
    //   "& .no-shaddow": {
    //     boxShadow: "none",
    //   },
    //   "& .MuiDataGrid-cell": {
    //     //border: "solid 1px red",
    //     borderBottomColor: colors.whiteAccent[300],
    //     color: colors.whiteAccent[900],
    //     fontSize: 14,
    //     "&:focus": {
    //       outline: "none",
    //     },
    //   },
    //   "& .name-column--cell": {
    //     //color: colors.greenAccent[300],
    //   },
    //   "& .MuiDataGrid-columnHeaders": {
    //     backgroundColor: colors.whiteAccent[100],
    //     borderBottomColor: colors.whiteAccent[200],
    //     fontSize: 14,
    //     fontWeight: 600,
    //     borderTopLeftRadius: "6px",
    //     borderTopRightRadius: "6px",
    //     "&:focus": {
    //       outline: "none",
    //       boxShadow: "none",
    //     },
    //   },
    //   "& .MuiDataGrid-virtualScroller": {
    //     backgroundColor: colors.whiteAccent[100],
    //   },
    //   "& .MuiDataGrid-footerContainer": {
    //     backgroundColor: colors.whiteAccent[100],
    //     borderTop: "none",
    //     fontSize: 14,
    //     fontWeight: 600,
    //     borderBottomLeftRadius: "6px",
    //     borderBottomRightRadius: "6px",
    //   },
    //   "& .MuiCheckbox-root": {
    //     color: `${colors.whiteAccent[800]} !important`,
    //     //color: `#94A3B8  !important`,
    //   },
    // },

    listContainer: {
      "& .MuiDataGrid-root": {
        border: "none",
        boxShadow: "rgba(15, 22, 36, 0.1) 0px 1px 3px",
      },
      "& .MuiDataGrid-cell": {
        borderBottomColor: "#E0E0E0", // Assuming a neutral color
        color: "#212121",
        fontSize: 14,
        "&:focus": {
          outline: "none",
        },
      },
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#FFFFFF", // Assuming light mode as default
        borderBottomColor: "#E0E0E0",
        fontSize: 14,
        fontWeight: 600,
        borderTopLeftRadius: "6px",
        borderTopRightRadius: "6px",
        "&:focus": {
          outline: "none",
        },
      },
      "& .MuiDataGrid-virtualScroller": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiDataGrid-footerContainer": {
        backgroundColor: "#FFFFFF",
        borderTop: "none",
        fontSize: 14,
        fontWeight: 600,
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
      },
      "& .MuiCheckbox-root": {
        color: "#212121 !important", // Neutral dark color
      },
    },

    boxContainer: {
      bgcolor: "background.paper",
      boxShadow:
        "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(15, 22, 36, 0.06) 0px 1px 2px 0px, rgba(15, 22, 36, 0.1) 0px 1px 3px 0px",
      borderRadius: "4px",
      padding: "32px 32px !important",
      "& .profile-image-box": {
        width: "100px",
        height: "100px",
        borderRadius: "4px",
        marginBottom: "30px",
        overflow: "hidden",
        boxShadow:
          "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(15, 22, 36, 0.06) 0px 1px 2px 0px, rgba(15, 22, 36, 0.1) 0px 1px 3px 0px",
        "& img": {
          maxWidth: "100px",
          maxHeight: "100px",
          objectFit: "cover",
        },
      },
    },
  };
};

export default dataGridStyles;
