import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useCreateQuickOrderMutation } from "services";
import { setConfirmModalConfig } from "store/slices/utilSlice";

const useAcceptOrder = ({ refetch }) => {
  const [createQuickOrder] = useCreateQuickOrderMutation();
  const dispatch = useDispatch();

  const handleAcceptOrder = async (orderId) => {
    try {
      // create shipment
      const shipmentResult = await createQuickOrder({ order_id: orderId });
      if (shipmentResult?.data?.payload?.awb_assign_error) {
        toast.info(shipmentResult?.data?.payload?.awb_assign_error);
      } else if (shipmentResult?.data?.payload?.shipment_id) {
        toast.success("Order accepted successfully!");
        refetch();
      } else {
        toast.error("Something went wrong!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onClickAccept = (orderId) => {
    dispatch(
      setConfirmModalConfig({
        visible: true,
        data: {
          onSubmit: () => handleAcceptOrder(orderId),
          content: {
            heading: "Accept Order",
            description: "Are you sure you want to accept this order?",
          },
        },
      })
    );
  };

  return { onClickAccept };
};

export default useAcceptOrder;
