import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import EyeIcon from "@mui/icons-material/Visibility";
import { Box, Button, Chip, Switch, TextField, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useDebounce, useDeleteRecord } from "hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetCouponListQuery,
  useUpdateCouponStatusMutation,
} from "services/coupon.service";
import { tokens } from "theme";
import dataGridStyles from "../../styles/dataGridStyles";
import { useState } from "react";
import StyledDataGrid from "components/datagrids/StyledDataGrid";

const List = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchName = searchParams.get("name");
  const debouncedNameQry = useDebounce(searchName, 500);
  // queries
  const {
    data = {},
    isLoading,
    refetch,
  } = useGetCouponListQuery(
    { params: { search: debouncedNameQry ?? undefined } },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { items: couponsData = [], count } = data ?? {};

  const [updateCouponStatus] = useUpdateCouponStatusMutation();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const styles = dataGridStyles(theme.palette.mode);
  const navigate = useNavigate();

  const handleChange = (evt) => {
    setSearchParams({
      name: evt.target.value,
    });
  };
  const columns = [
    {
      field: "code",
      headerName: "Code",
      flex: 1,
    },
    {
      field: "discountType",
      headerName: "Type",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <span style={{ textTransform: 'capitalize'}}>{params.value}</span>
        )
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={params.value === "Active" ? "success" : "warning"}
          size="small"
          variant="outlined"
        />
      ),
    },
    {
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        const onToggleStatus = async (evt) => {
          console.log(evt);
          try {
            const result = await updateCouponStatus({
              id: params.row._id,
              status: evt.target.checked ? "Active" : "Inactive",
            });
          } catch (err) {
            console.log(err);
          }
        };
        return (
          <Box display="flex" justifyContent="center" gap={2}>
            {/* <Link to={`/coupons/edit/${params.row._id}`}>
              <EditIcon />
            </Link> */}
            <Link to={`/coupons/${params.row._id}`}>
              <EyeIcon />
            </Link>
            <Switch
              size="small"
              checked={params.row.status === "Active"}
              onChange={onToggleStatus}
              sx={{
                "&.MuiSwitch-root .MuiSwitch-switchBase": {
                  color: "black"
                },
              
                "&.MuiSwitch-root .Mui-checked": {
                 color: colors.blueAccent[600],
                },
                // "&.MuiSwitch-root .MuiSwitch-track": {
                //   backgroundColor: colors.blueAccent[600],
                // }
               }}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Box
        sx={{
          ...styles.headingsContainer,
          ...styles.dFlex,
          ...styles.alignItemsStart,
          ...styles.justifyContentBetween,
        }}
      >
        <Box sx={styles.mainHeadings}>
          <Header title="COUPONS" subtitle="Managing the Coupons" />
        </Box>
        <Box>
          <Button sx={styles.buttonMd} LinkComponent={Link} to="/coupons/add">
            <AddOutlinedIcon sx={{ mr: "10px" }} />
            Add Coupon
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          ...styles.formContainer,
        }}
      >
        <Box
          sx={{
            ...styles.searchBar,
          }}
        >
          <TextField
            placeholder="Search"
            onChange={handleChange}
            value={searchName}
          />
        </Box>

        <StyledDataGrid
          rows={couponsData}
          columns={columns}
          getRowId={(row) => row._id}
          loading={isLoading}
          pagination
          page={page}
          pageSize={pageSize}
          rowCount={count}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          noResultText="No coupons found"
        />
      </Box>
    </Box>
  );
};

export default List;
