import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  sectionInitialValues,
  sectionValidationSchema,
} from "constants/schemas/masters";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddSectionMutation,
  useEditSectionMutation,
  useGetCaptionTagQuery,
} from "services";
import { setShowMasterModal } from "store/slices/utilSlice";
import { Autocomplete, useTheme } from "@mui/material";
import dataGridStyles from "../../../styles/dataGridStyles";
import { preFillValues } from "utils/common";

const SectionContent = () => {
  const dispatch = useDispatch();

  // store
  const selectedModelContent = useSelector(
    (state) => state.utils.selectedModelContent
  );

  // mutations
  const [sectionMutation] = useAddSectionMutation();
  const [editSectionMutation] = useEditSectionMutation();
  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  const {
    data: tagsData = [],
    isLoading,
    refetch,
  } = useGetCaptionTagQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const formik = useFormik({
    initialValues: preFillValues(sectionInitialValues, selectedModelContent),
    validationSchema: sectionValidationSchema,
    onSubmit: (values) => {
      if (selectedModelContent?._id) {
        editSectionMutation({
          payload: values,
          id: selectedModelContent._id,
        });
      } else {
        sectionMutation(values);
      }
      dispatch(setShowMasterModal(false));
    },
  });

  return (
    <Box sx={{ ...styles.formContainer, ...styles.modalContainer }}>
      <Typography variant="h6" gutterBottom>
        {selectedModelContent?._id ? "Edit Section" : "Add New Section"}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          name="name"
          error={formik.touched.name && Boolean(formik.errors.name)}
        />
        <Autocomplete
          options={tagsData}
          getOptionLabel={(option) => option.name}
          onBlur={formik.handleBlur}
          onChange={(_, value) => {
            formik.setFieldValue("tag", value.name);
          }}
          name="tag"
          sx={{ my: 2 }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label="Tag"
              InputLabelProps={{ shrink: true }}
              onBlur={formik.handleBlur}
              value={formik.values.tag}
              name="tag"
              error={formik.touched.tag && Boolean(formik.errors.tag)}
            />
          )}
          renderOption={(props, option) => {
            const isDisabled = option.name === formik.values.tag;
            return (
              <li
                {...props}
                style={{
                  pointerEvents: isDisabled ? "none" : "auto",
                  opacity: isDisabled ? 0.5 : 1,
                }}
              >
                {option?.name}
              </li>
            );
          }}
          value={tagsData?.length ? tagsData.find(item => item.name === formik.values.tag) : null}
        />
        <TextField
          type="number"
          label="Sort Order"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.sortOrder}
          name="sortOrder"
          error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)}
          className="input fieldset-1"
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default SectionContent;
