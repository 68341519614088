import { createSlice } from "@reduxjs/toolkit";

const userDataItem = localStorage.getItem("userData");

const initialState = {
  user: userDataItem ? JSON.parse(userDataItem) : {},
  token: localStorage.getItem("token") ?? null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const { token, user } = action.payload ?? {};
      if (token) {
        state.token = token;
        state.user = user;
        localStorage.setItem("token", token);
        localStorage.setItem("userData", JSON.stringify(user));
      }
    },
    logout: (state) => {
      state.token = null;
      state.user = {};
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
    },
  },
});

export const { logout, setUserData } = authSlice.actions;

export const authActions = {
  logout,
  setUserData,
};

export default authSlice.reducer;
