import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { toast } from "react-toastify";

const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem("token");

      if (token && !["uploadFile"].includes(endpoint)) {
        headers.set("Authorization", token);
      }
      headers.set("X-ESHOP-Platform", "ios");
      headers.set("X-ESHOP-Version", "1.0.0");
      headers.set("Accept-Language", "en");
      return headers;
    },
  }),
  endpoints: (builder) => ({}),
});

const handleErrors = (response) => {
  const { status, message } = response ?? {};
  if (status === 401) {
    toast.error("Unauthorized. Please log in again.");
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.href = window.location.origin;
  } else {
    if (message) {
      toast.error(message);
    } else {
      console.error("An error occurred.");
    }
  }
};

const handleResponse = (response) => {
  if (!response.success) {
    handleErrors(response);
  }
  return response.data ? response.data : response;
};

export { apiSlice, handleResponse };
