import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
// import { login, signup } from "../services/authService";


// Create the authentication context with an initial state
const AuthContext = createContext();

// Define a custom hook to access the authentication context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};



export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const token = localStorage.getItem("token");
  
  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
    }
  }, [token])

//   const signin = async (payload) => {
//     const response = await login(payload);
//     if (response?.access_token) {
//       setIsAuthenticated(true);
//     }
//     return response;
//   };

//   const register = async (payload,user_role_id) => {
//     const response = await signup({
//       ...payload,
//       user_role_id: user_role_id,
//     });
//     return response;
//   };

  const logout = () => {
    // Perform logout logic
    setIsAuthenticated(false);
    localStorage.removeItem("token");
  };

  const contextValue = {
    isAuthenticated,
    setIsAuthenticated,
    // signin,
    logout,
    // register,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
