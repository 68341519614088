import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CommonModal = ({ open, onClose, title, children }) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(5px)",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: "90%",
          maxWidth: 600,
          position: "relative",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={() => onClose(false)}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {children}
      </Box>
    </Modal>
  );
};

export default CommonModal;
