import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import TagIcon from "@mui/icons-material/Tag";
import SettingsIcon from "@mui/icons-material/Settings";
import PageviewIcon from "@mui/icons-material/Pageview";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PaletteIcon from "@mui/icons-material/Palette";
import DiscountIcon from "@mui/icons-material/Discount";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import EventIcon from "@mui/icons-material/Event";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PaymentIcon from "@mui/icons-material/Payment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import defaultUser from "../assets/admin-logo.png";
import { useSelector } from "react-redux";
import GroupIcon from "@mui/icons-material/Group";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const currentURL = to?.split("?")?.[0];
  return (
    <MenuItem
      active={selected === currentURL}
      onClick={() => setSelected(currentURL)}
      icon={icon}
      style={{
        color:
          selected === title
            ? colors.whiteAccent[100]
            : colors.blackAccent[600],
      }}
    >
      <Link to={to} style={{ textDecoration: "none", color: "inherit" }}>
        <Typography>{title}</Typography>
      </Link>
    </MenuItem>
  );
};

const Sidebar = ({ isCollapsed, setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const userInfo = useSelector((state) => state.auth.user);

  const [selected, setSelected] = useState(window.location.pathname);

  return (
    <Box
      sx={{
        "& .pro-sidebar": {
          " & > .pro-sidebar-inner": {
            height: "100%",
            "& > .pro-sidebar-layout": {
              height: "100%",
              "& > .pro-menu": {
                height: "100%",
              },
              overflowY: "hidden",
              "& ul": {
                height: "100%",
              },
              "& .scrollable-menu": {
                height: "calc(100% - 270px)",
                overflowY: "auto",
                overflowX: "hidden",
              },
            },
          },
        },
        "& .pro-sidebar-inner": {
          background: `${colors.whiteAccent[100]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
          color: `${colors.blackAccent[600]}`,
          fontWeight: 500,
        },
        "& .pro-inner-item:hover": {
          color: `${colors.blackAccent[600]} !important`,
        },
        "& .pro-menu-item.active": {
          background: `${colors.blueAccent[600]} !important`,
          "& .pro-inner-item": {
            color: `${colors.whiteAccent[100]} !important`,
          },
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.blackAccent[800]}>
                  ADMIN
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={defaultUser}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.blackAccent[800]}
                  fontWeight={500}
                  sx={{ m: "10px 0 0 0" }}
                >
                  {import.meta.env.VITE_PANEL_TITLE}
                </Typography>
                <Typography variant="h5" color={colors.blueAccent[600]}>
                {import.meta.env.VITE_PANEL_TAGLINE}
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            paddingLeft={isCollapsed ? undefined : "10%"}
            className="scrollable-menu"
          >
            {userInfo?.roles === "SUBADMIN" ? (
              <>
                <Item
                  title="Dashboard"
                  to="/dashboard"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Products"
                  to="/products"
                  icon={<ShoppingCartIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Orders"
                  to="/orders"
                  icon={<TagIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            ) : (
              <>
                <Item
                  title="Dashboard"
                  to="/dashboard"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Categories"
                  to="/categories"
                  icon={<CategoryIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Products"
                  to="/products"
                  icon={<ShoppingCartIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Orders"
                  to="/orders"
                  icon={<TagIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Coupons"
                  to="/coupons"
                  icon={<DiscountIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Static Pages"
                  to="/static-pages"
                  icon={<PageviewIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Sub Admins"
                  to="/sub-admins"
                  icon={<SupervisedUserCircleIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Users"
                  to="/users"
                  icon={<GroupIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="General Setting"
                  to="/general-setting"
                  icon={<SettingsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Masters
                </Typography>
                <Item
                  title="Size Type"
                  to="/size-types?type=sizeTypes"
                  icon={<FilterAltIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Caption Tag"
                  to="/caption-tag?type=captionTags"
                  icon={<TagIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Cloth Length"
                  to="/cloth-length?type=clothLengths"
                  icon={<FilterAltIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Colors"
                  to="/colors?type=colors"
                  icon={<PaletteIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Discount"
                  to="/discount?type=discounts"
                  icon={<DiscountIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Fabric"
                  to="/fabric?type=fabrics"
                  icon={<LocalMallIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Occasions"
                  to="/occasions?type=occasions"
                  icon={<EventIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Patterns"
                  to="/patterns?type=patterns"
                  icon={<ColorLensIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Brands"
                  to="/brands?type=brands"
                  icon={<LocalOfferIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Sections"
                  to="/sections?type=sections"
                  icon={<FormatListBulletedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            )}
            {/* <Item
              title="Payment Modes"
              to="/payment-modes?type=paymentModes"
              icon={<PaymentIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Profile"
              to="/profile"
              icon={<AccountCircleIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
