import {
  Box,
  Button,
  TextField,
  useTheme
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "components/Header";
import {
  subAdminInitialValues,
  subAdminValidationSchema,
} from "constants/schemas";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddSubAdminMutation,
  useGetSubAdminInfoQuery,
  useUpdateSubAdminMutation,
} from "services";
import dataGridStyles from "../../styles/dataGridStyles";
import { toast } from "react-toastify";

const Add = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const { id: subAdminId } = useParams();

  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  // hooks
  const navigate = useNavigate();

  // query
  const { data: subAdminData } = useGetSubAdminInfoQuery(subAdminId, {
    skip: !subAdminId,
    refetchOnMountOrArgChange: true,
  });

  // mutation
  const [addSubAdmin] = useAddSubAdminMutation();
  const [updateSubAdmin] = useUpdateSubAdminMutation();

  const savedSubAdminValues = {
    firstName: subAdminData?.firstName || "",
    lastName: subAdminData?.lastName || "",
    email: subAdminData?.email || "",
    password: "",
    countryCode: subAdminData?.countryCode || "",
    contactNumber: subAdminData?.contactNumber || "",
  };

  const formik = useFormik({
    initialValues: { ...subAdminInitialValues, ...savedSubAdminValues },
    enableReinitialize: true,
    validationSchema: subAdminValidationSchema(subAdminId),
    onSubmit: (values) => {
      if (subAdminId) {
        updateSubAdmin({
          subAdminId,
          payload: values,
        })
          .unwrap()
          .then((result) => {
            if (result?._id) {
              toast.success("SubAdmin updated successfully");
              navigate("/sub-admins");
            }
          });
      } else {
        addSubAdmin(values)
          .unwrap()
          .then((result) => {
            if (result?._id) {
              formik.resetForm();
              toast.success("SubAdmin created successfully");
              navigate("/sub-admins");
            }
          });
      }
    },
  });

  return (
    <Box m="20px">
      <Box sx={styles.mainHeadings}>
        <Header
          title={`${subAdminId ? "EDIT" : "CREATE"} SUBADMIN`}
          subtitle={`${subAdminId ? "Edit SubAdmin" : "Create a New SubAdmin"}`}
        />
        {subAdminId && (
          <Box display="flex" justifyContent="end">
            <Button
              onClick={() => navigate("/sub-admins")}
              color="secondary"
              variant="contained"
              sx={styles.buttonMd}
            >
              Back to SubAdmins
            </Button>
          </Box>
        )}
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            ...styles.formContainer,
          }}
          className="extra-space"
        >
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
              },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="First Name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.firstName}
              name="firstName"
              error={!!formik.touched.firstName && !!formik.errors.firstName}
              helperText={formik.touched.firstName && formik.errors.firstName}
              sx={{ gridColumn: "span 2" }}
              className="input"
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Last Name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.lastName}
              name="lastName"
              error={!!formik.touched.lastName && !!formik.errors.lastName}
              helperText={formik.touched.lastName && formik.errors.lastName}
              sx={{ gridColumn: "span 2" }}
              className="input"
            />
            <TextField
              fullWidth
              variant="filled"
              type="email"
              label="Email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              name="email"
              error={!!formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
              sx={{ gridColumn: "span 4" }}
              className="input"
            />
            {!subAdminId && (
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                name="password"
                error={!!formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                sx={{ gridColumn: "span 4" }}
                className="input"
              />
            )}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Country Code"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.countryCode}
              name="countryCode"
              error={!!formik.touched.countryCode && !!formik.errors.countryCode}
              helperText={formik.touched.countryCode && formik.errors.countryCode}
              sx={{ gridColumn: "span 1" }}
              className="input"
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Contact Number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.contactNumber}
              name="contactNumber"
              error={!!formik.touched.contactNumber && !!formik.errors.contactNumber}
              helperText={formik.touched.contactNumber && formik.errors.contactNumber}
              sx={{ gridColumn: "span 3" }}
              className="input"
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={styles.buttonMd}
            >
              {subAdminId ? "Update SubAdmin" : "Create SubAdmin"}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Add;
