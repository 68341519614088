import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import dataGridStyles from "../../styles/dataGridStyles";

const Container = styled(Box)`
  height: 70vh;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows[2]};
  border: 1px solid ${({ theme }) => theme.palette.divider}; /* Adds a subtle border to unify the look */
`;

const StyledDataGrid = ({
  rows,
  columns,
  getRowId,
  loading,
  pagination,
  page,
  pageSize,
  rowCount,
  paginationMode,
  onPageChange,
  onPageSizeChange,
  noResultText = "No results found.",
  customStyle = {},
  containerStyle = {},
  ...rest
}) => {
  const styles = dataGridStyles();

  return (
    <Container style={containerStyle}>
      <DataGrid
        sx={{
          ...styles.listContainer,
          border: "none", // Removes default borders
          borderRadius: 1, // Ensures corners are rounded
          boxShadow: "none", // Removes default shadows
          ...customStyle, // Apply custom styles
        }}
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        loading={loading}
        pagination={pagination}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        paginationMode={paginationMode}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        {...rest} // Forward any remaining props to DataGrid
        disableColumnFilter
        localeText={{
          noRowsLabel: noResultText,
        }}
      />
    </Container>
  );
};

export default StyledDataGrid;
