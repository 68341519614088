import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const UnProtectedLayout = ({ data }) => {
  const token = useSelector((state) => state.auth.token);

  if (token) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <Box className="unprotected-pages">
      <Outlet />
    </Box>
  );
};

export default UnProtectedLayout;
