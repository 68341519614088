import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "store/slices/authSlice";

const ProfileMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const handleClick = (event) => {
    setOpen(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logout());
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <PersonOutlinedIcon />
      </IconButton>
      <Menu anchorEl={open} open={Boolean(open)} onClose={() => setOpen(null)}>
        {/* <MenuItem onClick={() => setOpen(null)}>Profile</MenuItem> */}
        <MenuItem onClick={() => navigate('/change-password')}>Change Password</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
