import * as Yup from "yup";

// INITIAL VALUES

export const signInInitialValues = {
  email: "",
  password: "",
};

export const changePasswordInitialValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

// VALIDATION SCHEMA

export const signInValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
});

export const changePasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "New password must be at least 8 characters long"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Please confirm your new password"),
});
