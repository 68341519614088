import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "components/Header";
import { useFormik } from "formik";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useGetCouponQuery,
} from "services/coupon.service";
import dataGridStyles from "../../styles/dataGridStyles";
import { couponValidationSchema, couponInitialValues } from "constants/schemas";
import { useGetCategoriesQuery } from "services";
import { formatDateTimeLocalMUI } from "utils/common";

const Add = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const { id: couponId } = useParams();

  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  // hooks
  const navigate = useNavigate();

  // query
  const { data: couponData } = useGetCouponQuery(couponId, {
    skip: !couponId,
    refetchOnMountOrArgChange: true,
  });
  const { data: categories } = useGetCategoriesQuery();

  // mutation
  const [createCoupon] = useCreateCouponMutation();
  const [updateCoupon] = useUpdateCouponMutation();

  const savedData = {
    code: couponData?.code,
    description: couponData?.description,
    discountType: couponData?.discountType,
    discountValue: couponData?.discountValue,
    maxDiscount: couponData?.maxDiscount,
    expiry: couponData?.expiry,
    maxUses: couponData?.maxUses,
    minCartValue: couponData?.minCartValue,
    status: couponData?.status,
    bundleCategory: couponData?.bundleDetails?.category,
    bundleQuantity: couponData?.bundleDetails?.quantity,
    bundlePrice: couponData?.bundleDetails?.bundlePrice,
  };

  const formik = useFormik({
    initialValues: { ...couponInitialValues },
    enableReinitialize: true,
    validationSchema: couponValidationSchema,
    onSubmit: (values) => {
      let payload = {};
      if (values.discountType === "flat") {
        const {
          bundlePrice,
          bundleQuantity,
          bundleDetails,
          bundleCategory,
          maxDiscount,
          ...rest
        } = values;
        payload = rest;
      } else if (values.discountType === "percentage") {
        const {
          bundlePrice,
          bundleQuantity,
          bundleCategory,
          bundleDetails,
          ...rest
        } = values;
        payload = rest;
      } else if (values.discountType === "bundle") {
        const {
          bundlePrice,
          bundleQuantity,
          bundleCategory,
          maxDiscount,
          discountValue,
          minCartValue,
          ...rest
        } = values;
        payload = {
          ...rest,
          bundleDetails: {
            bundlePrice,
            quantity: bundleQuantity,
            category: bundleCategory,
          },
        };
      }

      if (couponId) {
        updateCoupon({
          body: payload,
          id: couponId,
        })
          .unwrap()
          .then((result) => {
            if (result?._id) {
              navigate("/coupons");
            }
          });
      } else {
        createCoupon(payload)
          .unwrap()
          .then((result) => {
            if (result?._id) {
              formik.resetForm();
              navigate("/coupons");
            }
          });
      }
    },
  });

  return (
    <Box m="20px">
      <Box sx={styles.mainHeadings}>
        <Header
          title={`${couponId ? "EDIT" : "CREATE"} COUPON`}
          subtitle={`${couponId ? "Edit Coupon" : "Create a New Coupon"}`}
        />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            ...styles.formContainer,
          }}
          className="extra-space"
        >
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
              },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Code"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.code}
              name="code"
              error={!!formik.touched.code && !!formik.errors.code}
              helperText={formik.touched.code && formik.errors.code}
              sx={{ gridColumn: "span 4" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
              name="description"
              error={
                !!formik.touched.description && !!formik.errors.description
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              sx={{ gridColumn: "span 4" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            <FormControl sx={{ gridColumn: "span 4" }}>
              <InputLabel id="demo-simple-select-helper-label">
                Discount Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.discountType ?? ''}
                label="Discount Type"
                name="discountType"
                onChange={formik.handleChange}
                placeholder="Select Discount Type"
                onBlur={formik.handleBlur}
                error={
                  !!formik.touched.discountType && !!formik.errors.discountType
                }
                helperText={
                  formik.touched.discountType && formik.errors.discountType
                }
              >
                <MenuItem value="flat">Flat</MenuItem>
                <MenuItem value="percentage">Percentage</MenuItem>
                <MenuItem value="bundle">Bundle</MenuItem>
              </Select>
            </FormControl>
            {formik.values.discountType === "percentage" && (
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Max Discount"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.maxDiscount}
                name="maxDiscount"
                error={
                  !!formik.touched.maxDiscount && !!formik.errors.maxDiscount
                }
                helperText={
                  formik.touched.maxDiscount && formik.errors.maxDiscount
                }
                sx={{ gridColumn: "span 4" }}
                className="input"
                InputLabelProps={{ shrink: true }}
              />
            )}
            <TextField
              fullWidth
              variant="filled"
              type="datetime-local"
              label="Expiry"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formatDateTimeLocalMUI(formik.values.expiry)}
              name="expiry"
              error={!!formik.touched.expiry && !!formik.errors.expiry}
              helperText={formik.touched.expiry && formik.errors.expiry}
              sx={{ gridColumn: "span 4" }}
              className="input"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: { min: new Date().toISOString().slice(0, 16) },
              }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Max Uses"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.maxUses}
              name="maxUses"
              error={!!formik.touched.maxUses && !!formik.errors.maxUses}
              helperText={formik.touched.maxUses && formik.errors.maxUses}
              sx={{ gridColumn: "span 4" }}
              className="input"
              InputLabelProps={{ shrink: true }}
            />
            {formik.values.discountType !== "bundle" && (
              <>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Min Cart Value"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.minCartValue}
                  name="minCartValue"
                  error={
                    !!formik.touched.minCartValue &&
                    !!formik.errors.minCartValue
                  }
                  helperText={
                    formik.touched.minCartValue && formik.errors.minCartValue
                  }
                  sx={{ gridColumn: "span 4" }}
                  className="input"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Discount Value"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.discountValue}
                  name="discountValue"
                  error={
                    !!formik.touched.discountValue &&
                    !!formik.errors.discountValue
                  }
                  helperText={
                    formik.touched.discountValue && formik.errors.discountValue
                  }
                  sx={{ gridColumn: "span 4" }}
                  className="input"
                  InputLabelProps={{ shrink: true }}
                />
              </>
            )}
            <FormControl sx={{ gridColumn: "span 4" }}>
              <InputLabel id="demo-simple-select-helper-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.status ?? ''}
                label="Status"
                onChange={formik.handleChange}
                placeholder="Select Status"
                onBlur={formik.handleBlur}
                name="status"
                error={!!formik.touched.status && !!formik.errors.status}
                helperText={formik.touched.status && formik.errors.status}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
            {formik.values.discountType === "bundle" && (
              <>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Bundle Quantity"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.bundleQuantity}
                  name="bundleQuantity"
                  error={
                    !!formik.touched.bundleQuantity &&
                    !!formik.errors.bundleQuantity
                  }
                  helperText={
                    formik.touched.bundleQuantity &&
                    formik.errors.bundleQuantity
                  }
                  sx={{ gridColumn: "span 4" }}
                  className="input"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Bundle Price"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.bundlePrice}
                  name="bundlePrice"
                  error={
                    !!formik.touched.bundlePrice && !!formik.errors.bundlePrice
                  }
                  helperText={
                    formik.touched.bundlePrice && formik.errors.bundlePrice
                  }
                  sx={{ gridColumn: "span 4" }}
                  className="input"
                  InputLabelProps={{ shrink: true }}
                />
                <Autocomplete
                  options={categories}
                  getOptionLabel={(option) => option.name}
                  onBlur={formik.handleBlur}
                  onChange={(_, value) =>
                    formik.setFieldValue("bundleCategory", value?._id)
                  }
                  name="bundleCategory"
                  sx={{ gridColumn: "span 4" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="filled"
                      label="Select Category"
                      error={
                        !!formik.touched.bundleCategory &&
                        !!formik.errors.bundleCategory
                      }
                      helperText={
                        formik.touched.bundleCategory &&
                        formik.errors.bundleCategory
                      }
                    />
                  )}
                  value={categories?.find(
                    (category) => category?._id === formik.values.bundleCategory
                  )}
                />
              </>
            )}
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={styles.buttonMd}
            >
              {couponId ? "Update" : "Create"}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Add;
