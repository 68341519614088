import { CheckCircleOutline } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

const FileUploadProgress = ({ progress }) => {
  return (
    <>
      {progress > 0 && progress < 100 ? (
        <CircularProgress
          variant="determinate"
          value={progress}
          sx={{ width: "100%" }}
        />
      ) : progress === 100 ? (
        <CheckCircleOutline color="success" />
      ) : null}
    </>
  );
};

export default FileUploadProgress;
