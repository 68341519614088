import { Autocomplete, Chip, useTheme } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  sizeTypeInitialValues,
  sizeTypeValidationSchema,
} from "constants/schemas/masters";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useAddSizeTypesMutation, useEditSizeTypesMutation } from "services";
import { setShowMasterModal } from "store/slices/utilSlice";
import dataGridStyles from "../../../styles/dataGridStyles";
import { preFillValues } from "utils/common";

const SizeTypeContent = () => {
  const dispatch = useDispatch();
  // store
  const selectedModelContent = useSelector(
    (state) => state.utils.selectedModelContent
  );
  // mutations
  const [sizeTypesMutation] = useAddSizeTypesMutation();
  const [editSizeTypesMutation] = useEditSizeTypesMutation();
  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);

  const formik = useFormik({
    initialValues: preFillValues(sizeTypeInitialValues, selectedModelContent),
    validationSchema: sizeTypeValidationSchema,
    onSubmit: (values) => {
      if(selectedModelContent?._id) {
        editSizeTypesMutation({
          payload: values,
          id: selectedModelContent._id,
        });
      } else {
        sizeTypesMutation(values);
      }
      dispatch(setShowMasterModal(false));
    },
  });

  const filter = createFilterOptions();

  const handleChange = (event, newValue) => {
    const updatedNewValue = newValue.map((item) => {
      if (typeof item === "string") {
        return item;
      }
      if (item.inputValue) {
        return item.inputValue;
      }
      return item;
    });

    const uniqueValues = Array.from(new Set(updatedNewValue));
    formik.setFieldValue("values", uniqueValues);
  };

  return (
    <Box sx={{ ...styles.formContainer, ...styles.modalContainer }} >
      <Typography variant="h6" gutterBottom>
        {selectedModelContent?._id ? "Edit Size Type" : "Add New Size Type"}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          name="name"
          error={formik.touched.name && Boolean(formik.errors.name)}
          // className="input"
        />
        <Autocomplete
          multiple
          value={formik.values.values}
          onChange={handleChange}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (params.inputValue !== "") {
              filtered.push(params.inputValue);
            }

            return filtered;
          }}
          options={formik.values.values}
          getOptionLabel={(option) => option}
          renderOption={(props, option) => <li {...props}>{option}</li>}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Values"
              placeholder="Create options"
              error={formik.touched.values && Boolean(formik.errors.values)}
            />
          )}
          className="input"
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default SizeTypeContent;
