import { Route } from "react-router-dom";
import { Signin } from "scenes/auth/Signin";

const UnProtectedRoutes = () => {
  return (
    <>
      <Route index path="login" element={<Signin />} />
      <Route index path="/" element={<Signin />} />
    </>
  );
};

export default UnProtectedRoutes;
