import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  IconButton,
} from "@mui/material";
import {
  changePasswordInitialValues,
  changePasswordValidationSchema,
} from "constants/schemas";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { useChangeSubAdminPasswordMutation } from "services";
import CommonModal from "./CommonModal";
import * as Yup from "yup";

const ChangePasswordModal = ({
  show,
  setShow,
  selectedRow,
  setSelectedRow,
}) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // mutation
  const [changePassword] = useChangeSubAdminPasswordMutation();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .required("New password is required")
        .min(8, "New password must be at least 8 characters long"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Please confirm your new password"),
    }),
    onSubmit: (values) => {
      changePassword({
        ...values,
        subadminId: selectedRow?._id,
      })
        .unwrap()
        .then((result) => {
          toast.success("Password changed successfully");
          formik.resetForm();
          setShow(false);
          setSelectedRow(null);
        });
    },
  });

  return (
    <CommonModal
      open={show}
      onClose={() => {
        setShow(false);
        setSelectedRow(null);
      }}
      title={"Change Password"}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            gap: 2,
          }}
        >
          <TextField
            fullWidth
            margin="normal"
            id="newPassword"
            name="newPassword"
            label="New Password"
            type={showNewPassword ? "text" : "password"}
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle new password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            margin="normal"
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}
        >
          <Button
            type="submit"
            variant=""
            style={{
              backgroundColor: "#846CF9",
              color: "white",
              border: "none",
            }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </CommonModal>
  );
};

export default ChangePasswordModal;
