import { Box, Button, Grid, InputLabel, TextField, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "components/Header";
import parse from "html-react-parser";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCategoriesQuery, useGetCategoryInfoQuery } from "services";
import dataGridStyles from "../../styles/dataGridStyles";

const View = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const styles = dataGridStyles(theme.palette.mode);
  const { id } = useParams();

  const { data: categoryData } = useGetCategoryInfoQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const navigate = useNavigate();

  return (
    <Box m="20px">
      <Box sx={styles.mainHeadings}>
        <Header title="CATEGORY DETAILS" />

        <Box display="flex" justifyContent="end" mt="20px">
          <Button
            onClick={() => navigate("/categories")}
            color="secondary"
            variant="contained"
            sx={styles.buttonMd}
          >
            Back to Categories
          </Button>
        </Box>
      </Box>
      <Box sx={styles.formContainer}>
        <Grid sx={styles.viewForm} spacing={2}>
          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Slug</InputLabel>
              <input
                value={categoryData?.slug}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>


          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Name:</InputLabel>
              <input
                value={categoryData?.name}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>

          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Description:</InputLabel>
              <textarea
                value={parse(categoryData?.description ?? "")}
                disabled
                className="form-control"
              />
            </Box>
          </Grid>


          <Grid item lg={6}>
            <Box className="d-flex mb-15">
              <InputLabel className="control-label">Main Image:</InputLabel>
              <img src={categoryData?.image} alt="Main" width={100} height={100} />
            </Box>
          </Grid>




        </Grid>


      </Box>
    </Box>
  );
};

export default View;
