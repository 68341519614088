import { API_ENDPOINTS } from "../constants/endpoints";
import { apiSlice, handleResponse } from "./base.service";

export const couponApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCoupon: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.CREATE_COUPON,
        method: "POST",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    updateCoupon: builder.mutation({
      query: ({body, id}) => ({
        url: `${API_ENDPOINTS.CREATE_COUPON}/${id}`,
        method: "PUT",
        body,
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    updateCouponStatus: builder.mutation({
      query: ({status, id}) => ({
        url: `${API_ENDPOINTS.CREATE_COUPON}/${id}/${status}`,
        method: "PUT",
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      invalidatesTags: ['coupons-list']
    }),
    getCoupon: builder.query({
      query: (id) => ({
        url: `${API_ENDPOINTS.GET_COUPON}/${id}`,
        method: "GET",
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
    }),
    getCouponList: builder.query({
      query: ({params}) => ({
        url: API_ENDPOINTS.GET_COUPON,
        method: "GET",
        params
      }),
      transformResponse: handleResponse,
      transformErrorResponse: handleResponse,
      providesTags: ['coupons-list']
    }),
  }),
});

export const { useCreateCouponMutation, useGetCouponQuery, useUpdateCouponMutation, useGetCouponListQuery, useUpdateCouponStatusMutation } = couponApiSlice;
