import { useDeleteRowMutation } from "services/utils.service";

const useDeleteRecord = () => {
  // mutation
  const [deleteRow] = useDeleteRowMutation();

  const deleteRecord = async (payload, refetch) => {
    try {
      await deleteRow(payload);
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  return { deleteRecord };
};

export default useDeleteRecord;
