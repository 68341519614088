import { firebaseStorage } from "config/firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

export const handleFileUpload = async (
  file,
  folderPath,
  onUploadProgress,
  renderResult
) => {
  try {
    const storage = firebaseStorage;

    const storageRef = ref(storage, `${folderPath}/${file.name}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        if (onUploadProgress) {
          onUploadProgress(progress);
        }
      },
      (error) => {
        console.error("Error uploading file:", error);
        throw error;
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        renderResult(downloadURL);
      }
    );
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const preFillValues = (sectionInitialValues, savedValues) => {
  const initialValues = {};
  Object.keys(sectionInitialValues).forEach((key) => {
    initialValues[key] = savedValues[key] || sectionInitialValues[key];
  });
  return initialValues;
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const day = date.getDate().toString().padStart(2, "0");
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[date.getMonth()];
  const formattedDate = `${day} ${month}, ${year}`;
  return formattedDate;
};

export function arraysAreEqual(arr1, arr2) {
  // Check if lengths are the same
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Check if all corresponding elements are the same
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export const getAllSearchParams = (searchParams) => {
  const params = {};
  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }
  return params;
};

export const formatDateTimeLocalMUI = (isoString) => {
  if (!isoString) return "";
  const date = new Date(isoString);
  const pad = (num) => (num < 10 ? `0${num}` : num);
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const getFormattedDate = (date) => {
  const d = new Date(date);
  const month = d.toLocaleString("default", { month: "long" });
  const day = d.getDate();
  const year = d.getFullYear();
  return `${month} ${day}, ${year}`;
};
